import React, { Fragment } from 'react';
import { Pagination, ImageField, List, Datagrid, TextField, DateField, EditButton, Filter, TextInput, SelectInput, useTranslate } from 'react-admin';

import Typography from '@material-ui/core/Typography';

// import BatchDeleteButton from '../../control/button/batch-delete-button';


import { GENDER_MALE, GENDER_FEMALE, USER_TYPE_AUTO_ENTERPRENER, USER_TYPE_COMPANY_AND_DRIVER, ACTIVITY_TYPE_ATR, ACTIVITY_TYPE_VTC } from '../../util/constant';

import {
    FormattedDateField, DriverStatusField, VerifyField, AvatarEmailField, FullNameField, GenderField, GenderFilterField, LockField,
    ValidateField, AvatarField, DriverCompanyTypeField, DriverCompanyTypeFilterField
} from '../../control/field/index'
import { NoticeSendButton } from '../../control/button/index'

const BulkActionButtons = props => (
    <Fragment>
        {/* <BatchDeleteButton label="Supprimer" {...props} /> */}
        <NoticeSendButton {...props} toClient={false} />
    </Fragment>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.driver.list')}</span>;
};

var driverTypes = [];
driverTypes.push({ id: USER_TYPE_AUTO_ENTERPRENER, name: "Auto-entrepreneur" });
driverTypes.push({ id: USER_TYPE_COMPANY_AND_DRIVER, name: "Autre" });

// const UserTypeField = ({ record = {}, label}) => {
//     return <div>
//         <span class>{record.user_type == USER_TYPE_COMPANY_AND_DRIVER? 'Autre' : 'Auto-entrepreneur'}</span>
//     </div>;
// }

// const UserTypeFilterField = ({ record }) => {
//     return <span>{record.id == USER_TYPE_AUTO_ENTERPRENER ? 'Auto-entrepreneur' : 'Autre'}</span>;
// };

var activityTypes = [];
activityTypes.push({ id: ACTIVITY_TYPE_VTC, name: "VTC" });
activityTypes.push({ id: ACTIVITY_TYPE_ATR, name: "Transport léger des marchandises" });

const ActivityTypeField = ({ record = {}, label }) => {
    return <div>
        <Typography variant="body2">
            {record.activity_type == ACTIVITY_TYPE_VTC ? 'VTC' : "Transport léger des marchandises"}
        </Typography>
    </div>;
}

ActivityTypeField.defaultProps = {
    addLabel: true,
};

const ActivityTypeFilterField = ({ record }) => {
    return <span>{record.id == ACTIVITY_TYPE_VTC ? 'VTC' : "Transport léger des marchandises"}</span>;
};

// const FormattedDateField = ({ record = {}, source, label, locales, className}) => {
//     // const classes = useStyles();
//     if(typeof(record) === 'undefined' || 
//         typeof(record[source]) === 'undefined' 
//         || record[source] === "")
//         return <span></span>

//     moment.locale('fr');
//     const value = moment(record[source], "YYYY-MM-DD HH:mm:ss");
//     return <spam>{value.format('DD/MM/YYYY')}</spam>
// }

// FormattedDateField.defaultProps = {
//     addLabel: true,
// };

var genders = [];
genders.push({ id: GENDER_MALE, name: 'Monsieur' });
genders.push({ id: GENDER_FEMALE, name: "Madame" });

var lockeds = [];
lockeds.push({ id: 0, name: 'Non-vérrouillé' });
lockeds.push({ id: 1, name: "Vérrouillé" });

var validateds = [];
validateds.push({ id: 0, name: 'Non-validé' });
validateds.push({ id: 1, name: "Validé" });

var activateds = [];
activateds.push({ id: 0, name: 'Non-activé' });
activateds.push({ id: 1, name: "Activé" });

var userSubTypes = [];
userSubTypes.push({ id: 0, name: 'Commissionné' });
userSubTypes.push({ id: 1, name: "Abonné" });

const ListFilter = ({ permissions, ...props }) => (
    <Filter {...props}>
        <TextInput label="Identifiant parrain" source="user_code" alwaysOn resettable />
        <TextInput label="Email" source="email" alwaysOn resettable />
        <TextInput label="Nom de l'entreprise" source="company_name" alwaysOn resettable />
        {/* <TextInput label="Type de l'entreprise" source="company_type" alwaysOn resettable/>
        <SelectInput label="Abonné" source="user_sub_type" choices={userSubTypes}  alwaysOn
            allowEmpty emptyText="Abonné et commissionné" emptyValue='-1' initialValue='-1'
            /> */}
        {/* <TextInput label="Nom" source="last_name" alwaysOn resettable/>
        <TextInput label="Prénom" source="first_name" alwaysOn resettable/> */}
        {/* <SelectInput label="Type d'entreprise" source="user_type" alwaysOn choices={driverTypes} 
            optionText={<DriverCompanyTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            /> */}

        {/* <SelectInput label="Type d'activité" source="activity_type" alwaysOn choices={activityTypes} 
            optionText={<ActivityTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            /> */}

        {/* <TextInput label="Ville" source="city" resettable/>
        <TextInput label="Code postal" source="postal_code" resettable/>
        <SelectInput label="Civilité" source="gender" choices={genders} 
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/> */}



        <SelectInput label="Activation" source="activated" choices={activateds}
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
        />
        {/* <SelectInput label="Vérrouillage" source="locked" choices={lockeds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            /> */}
        <SelectInput label="Validation" source="validated" choices={validateds}
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
        />
    </Filter>
);

const ListPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;


const ListDriver = ({ permissions, ...props }) => {
    const translate = useTranslate();
    return (
        <List pagination={<ListPagination />} title={<Title />} {...props} perPage={50}
            filters={<ListFilter permissions={permissions} />}
            bulkActionButtons={<BulkActionButtons />} exporter={false} resource="driver" filter={{ user_type: USER_TYPE_COMPANY_AND_DRIVER }}>
            <Datagrid rowClick="show">
                <AvatarField source="photo_url" label="" />
                <TextField source="user_code" label="Identifiant parrain" />
                {/* <TextField source="id" label="ID"/> */}
                {/* <DriverCompanyTypeField source="user_type" label="Type d'entreprise"/> */}
                {/* <ActivityTypeField label="Type d'activité"/> */}
                {/* <TextField source="company_type" label="Type d'entreprise"/> */}
                <TextField source="company_name" label="Nom de l'entreprise" />
                <TextField source="email" label="Email" />
                <TextField source="full_mobile" label="Mobile" />
                <TextField source="company_address" label="Adresse" />
                <TextField source="postal_code" label="Code postal" />
                <TextField source="city" label="Ville" />
                {/* <TextField source="first_name" label="Prénom"/> */}


                {/* <TextField source="mobile" label="Mobile"/> */}
                {/* <VerifyField source="activated" label="Activé"/> */}
                {/* <DriverStatusField source="online_status" label="Statut"/> */}
                <FormattedDateField source="create_time" label="Date d'inscription" locales="fr-FR" />
                {/* <ValidateField source="user_sub_type" label='Abonné' /> */}
                <ValidateField source="activated" label='Activé' />
                <ValidateField source="validated" label='Validé' />

                <ValidateField source="locked" label='Verrouillé' />
                {/* <DateField source="create_time" label="Date de création"  locales="fr-FR"/> */}
                {/* <TextField source="creator.email" label="Créateur"/> */}
                {/* <DateField source="create_time" label="Date de création"  locales="fr-FR"/> */}
                {/* <DateField source="update_time" label="Date de modification"  locales="fr-FR"/> */}
                {/* <EditButton/> */}
            </Datagrid>
        </List>);
};

export default ListDriver;

