// export const BACKEND_URL = 'http://35.204.157.144/rest/v1/index.php/web';

import { LocationType } from '../util/constant'
import { createMuiTheme } from '@material-ui/core/styles';
// import error from '@material-ui/core/colors/red';
import {
  ACTIVITY_TYPE_VTC, ACTIVITY_TYPE_ATR, ORDER_ACTIVITY_TYPE_ANIMAL_ONLY, ORDER_ACTIVITY_TYPE_PASSENGER_WITH_ANIMAL,
  ORDER_ACTIVITY_TYPE_PASSENGER_ONLY
} from './constant'

// export const ROOT_URL = 'http://15.236.236.89:8080';
export const ROOT_URL = 'https://api.e-tcar.com';

export const ROOT_UMS_URL = ROOT_URL + '/ums';
export const ROOT_OMS_URL = ROOT_URL + '/oms';

export const UNAUTH_UMS_URL = ROOT_UMS_URL + '/rest/v1/index.php/web';
export const BACKEND_UMS_URL = UNAUTH_UMS_URL + '/auth';

export const UNAUTH_OMS_URL = ROOT_OMS_URL + '/rest/v1/web';
export const BACKEND_OMS_URL = UNAUTH_OMS_URL + '/auth';

export const BACKEND_API_KEY = 'etwb899j2hghdh77473743fdfsjffdksfj988afd';
export const GOOGLE_API_KEY = 'AIzaSyDRPbJjCGWuPtuuKA9myfCYYnzFU7C6zoM';

export const PUSHER_API_KEY = 'cbd007d238f473fce97e';
export const PUSHER_CLUSTER = 'eu';

export const PUSHER_CHANNEL_USER = 'user_channel';
export const PUSHER_CHANNEL_ORDER = 'order_channel';
export const PUSHER_CHANNEL_LOCATION = 'location_channel';
export const EVENT_ONLINE_DRIVERS_CHANGED = 'online_drivers_changed';
export const EVENT_DRIVERS_LOCATION_CHANGED = 'drivers_location_changed';

export const EVENT_ORDER_NEW = 'order_new';


export const BACKEND_FILE_URL = ROOT_URL;

export const locationTypes = [
  { id: LocationType.AIRPORT, name: 'Aéroport' },
  { id: LocationType.STATION, name: 'Gare' },
  // {id: LocationType.HOTEL, name: 'Hôtel'},
  // {id: LocationType.HOSPITAL, name: 'Hôpital'},
  // {id: LocationType.PLACE, name: 'Endroit'},
];


export const locationDict = {
  [LocationType.AIRPORT]: { id: LocationType.AIRPORT, name: 'Aéroport' },
  [LocationType.STATION]: { id: LocationType.STATION, name: 'Gare' },
  // [LocationType.HOTEL]:{id: LocationType.HOTEL, name: 'Hôtel'},
  // [LocationType.HOSPITAL]:{id: LocationType.HOSPITAL, name: 'Hôpital'},
  // [LocationType.PLACE]:{id: LocationType.PLACE, name: 'Endroit'}
};


export const activityTypes = [
  { id: ACTIVITY_TYPE_VTC, name: "VTC" }, { id: ACTIVITY_TYPE_ATR, name: "Transport léger des marchandises" }];

export const orderActivityTypes = [
  { id: ORDER_ACTIVITY_TYPE_PASSENGER_ONLY, name: "Passagers tout seul" },
  { id: ORDER_ACTIVITY_TYPE_PASSENGER_WITH_ANIMAL, name: "Passagers avec un animal" },
  { id: ORDER_ACTIVITY_TYPE_ANIMAL_ONLY, name: "Animaux non accompagnés" },
];

// activityTypes.push({id: ACTIVITY_TYPE_TAXI, name: "Taxi"});
// activityTypes.push({id: ACTIVITY_TYPE_VTC, name: "VTC"});
// activityTypes.push({id: ACTIVITY_TYPE_TPMR, name: "TPMR"});

export const theme = createMuiTheme({
  drawer: {
    width: 300,
    // closedWidth: 200,
    backgroundColor: '#001b4c'
  },

  sidebar: {
    width: 300,
    // closedWidth: 200,
    backgroundColor: '#001b4c',

  },
  palette: {
    secondary: {
      // main: '#2AEAA1',
      main: '#001b4c',
      // main: '#f9fcfb',
    },
    primary: {
      // main: '#FCC814',
      // main: '#B3AD3B',
      main: '#001b4c',
    },
    error: { main: '#ff0000' },
    // contrastThreshold: 3,
    // tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    // MuiButton: { // override the styles of all instances of this component
    //     root: { // Name of the rule
    //         color: 'white', // Some CSS
    //     },
    // },
    MuiFilledInput: {
      root: {
        borderRadius: 0,
        backgroundColor: "#fff",
      },
    },

    RaSidebar: {
      root: {
        backgroundColor: '#001b4c',
        // height: '500%',
      },
      modal: {
        backgroundColor: '#001b4c',
        // height: '500%',
      },
      paper: {
        backgroundColor: '#001b4c',
      },

      paperAnchorLeft: {
        backgroundColor: '#001b4c',
      },

      docked: {
        backgroundColor: '#001b4c',
      },

    },

    MuiDrawer: {
      modal: {
        backgroundColor: '#001b4c',
        // height: '500%',
      },


    },

    // MuiTableHead: {
    //   root:{
    //     RaDataGrid:{
    //       thead:{
    //         display:'none'
    //       }
    //     }
    //   }

    // }
  },
});

// export const locationTypes = [
//     { name: 'orderNumber', title: 'Order', width: '10%',  type: 'string' },
//     { name: 'orderType', title: 'Type', width: '10%',  type: 'string' }
//   ];


// var locationTypes = [];
// locationTypes.push({id: LocationType.AIRPORT, name: 'Aéroport'});
// locationTypes.push({id: LocationType.STATION, name: 'Gare'});
// locationTypes.push({id: LocationType.PLACE, name: 'Endroit'});
// export lo
