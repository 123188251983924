
import React from 'react';
import { TabbedForm, FormTab, TextInput, Edit, SimpleForm, SelectInput, required, useTranslate } from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import { ListButton, Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import { DateInput } from 'react-admin-date-inputs2';
// import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import frLocale from "date-fns/locale/fr";
import { locationTypes } from '../../util/variant'
import RichTextInput from 'ra-input-rich-text';
import { TemplateEmailTypeField } from '../../control/field';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbarNoStyle = ({ permissions, ...props }) => (
    <Toolbar {...props}>
        <SaveButton />
        {permissions === 'Admin' &&
            <DeleteButton label="ra.action.delete" redirect='list' {...props} />}
    </Toolbar>
);

const CustomToolbar = withStyles(toolbarStyles)(CustomToolbarNoStyle);

const cardActionStyle = {
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

const EditActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        <ListButton basePath={basePath} />
    </CardActions>
);
const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.email_template.edit')}: {record ? `${record.name}` : ''}</span>;
};

const EditEmailTemplate = ({ classes, permissions, ...props }) => (
    <Edit title={<Title />} {...props} actions={<EditActions />}>
        <SimpleForm toolbar={<CustomToolbar />}>
            {/* <TextInput source="code" label="Code" disabled fullWidth validate={required()} /> */}
            <TextInput source="name" label="Nom" fullWidth validate={required()} />
            <TextInput source="c_subject" label="Objet" fullWidth validate={required()} />
            <RichTextInput source="c_body" label="Contenu" fullWidth validate={required()} />
            <TemplateEmailTypeField label="Destinataire" />
            {/* <TextInput source="c_sender" label="Email d'expéditeur" 
                    initialValue="cedricloue@gmail.com"
                    validate={required()}/>
                <TextInput source="c_sender_name" label="Nom d'expéditeur" 
                    initialValue="Becab"
                    validate={required()}/> */}

        </SimpleForm>

        {/* <TabbedForm toolbar={<CustomToolbar />}>
            <FormTab label="Envoyer aux utilisateurs">
                <TextInput source="template.mail_backup.subject" label="Objet"/>
                <RichTextInput source="template.mail_backup.content" label="Contenu"/>
            </FormTab>
            <FormTab label="Envoyer à l'admin">
                <TextInput source="system.mail.sender" label="Expéditeur" validate={required()}/>
                <TextInput source="system.mail.receiver" label="Destinataire" validate={required()}/>
            </FormTab>
            
        </TabbedForm> */}


    </Edit>
);

export default EditEmailTemplate;