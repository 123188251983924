
import React, { Link, Component } from 'react';
import { Filter, ReferenceArrayField, SingleFieldList, ChipField, TextInput, ReferenceField, Pagination, DateField, ReferenceManyField, Datagrid, TextField, Show, useTranslate, TabbedShowLayout, Tab, ImageField } from 'react-admin';
import { EditButton, ListButton } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { Form } from 'react-final-form'
import Divider from '@material-ui/core/Divider';
import { DocApproveButton, DocRejectButton, DeleteButton, AvatarButton, DriverFieldEditButton, DriverFieldRejectButton, DriverSettingsButton } from '../../control/button/index';
import ActivateIcon from '@material-ui/icons/Check';
import NotActivateIcon from '@material-ui/icons/Clear';
import ImageViewer from '../../control/viewer/image-viewer';

import CardActions from '@material-ui/core/CardActions';

import {
    DocNameField, FileCountField, ActivityTypeField,
    ClientDataField, ValidityDateField, PhotoField,
    DocStatusReasonField, DocStatusField, OrderStatusField, FormattedDateField, DriverStatusField, AvatarField, DataStatusField, ValidateField,
    DriverCompanyTypeField,
    OrderTypeField
} from '../../control/field/index'
import {
    VehicleLockButton, VehicleUnlockButton, VehicleDateEditButton,
    VehicleApproveButton, VehicleRejectButton,
    DocDateEditButton, ValidateButton, CustomerLockButton, CustomerUnlockButton
} from '../../control/button/index'

import { Status, DataStatus, FALSE, TRUE, USER_TYPE_COMPANY_AND_DRIVER, USER_TYPE_AUTO_ENTERPRENER, ACTIVITY_TYPE_ATR, ACTIVITY_TYPE_VTC }
    from '../../util/constant'
import { BACKEND_FILE_URL, theme } from '../../util/variant'

import { makeStyles } from '@material-ui/core/styles';
import DriverFieldApproveButton from '../../control/button/driver-field-approve-button';
import { getStripeConnectDueName, normalizeStripeConnectDues } from '../../util/function';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: { paddingLeft: 50, color: '#c30040', },

    colorPrimary: { color: '#c30040', },

    noColorLabel: {
        paddingLeft: 50,
    },

    colorValue: { color: '#c30040', paddingLeft: 5 },

    supplementText: { color: '#c30040', },

    noHeader: {
        display: 'none',
        // height:'1px'
    },

    image: { width: '200px', height: '200px', }

});

const styles = {
    flex: { display: 'flex' },
    images: { display: 'flex', paddingLeft: '1em', marginLeft: "3em" },
    flexColumn: { display: 'flex', flexDirection: 'column', },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map: { flex: 1, marginTop: '1em', marginBottom: '1em' },
    button: { flex: 1, marginTop: '1em' },
};

const cardActionStyle = {
    // zIndex: 2,
    // display: 'inline-block',
    // float: 'right',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};

// const DocNameField = ({record, label}) => {
//     // const classes = useStyles();
//     console.log('Doc record: ' + JSON.stringify(record));
//     // if(typeof(record) !== 'undefined' && typeof(record.name) !== 'undefined'){
//     //     const nameObj = JSON.parse(record.name);
//     //     // const empty = record.files.length > 0? '(' + record.files.length + ')' : ''; 
//     //     // return <div><span>{nameObj.fr}</span><span className={classes.supplementText}>{empty}</span></div>
//     //     return <div><span>{nameObj.fr}</span></div>;
//     // }
//     if(typeof(record) !== 'undefined' && typeof(record.doc_name) !== 'undefined'){
//         // const nameObj = JSON.parse(record.name);
//         // const empty = record.files.length > 0? '(' + record.files.length + ')' : ''; 
//         // return <div><span>{nameObj.fr}</span><span className={classes.supplementText}>{empty}</span></div>
//         return <div><span>{record.doc_name}</span></div>;
//     }
//     return <div/>;

// }

// DocNameField.defaultProps = {
//     addLabel:true
// }

// const FileCountField = ({record, label}) => {
//     const classes = useStyles();
//     console.log('Doc record: ' + JSON.stringify(record));
//     if(typeof(record) !== 'undefined' && typeof(record.name) !== 'undefined'){
//         return <div><span className={classes.colorValue}>{record.files.length}</span></div>
//     }

//     return <div><span className={classes.colorValue}>0</span></div>

// }

// FileCountField.defaultProps = {
//     addLabel:true
// }


const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>

        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
        {typeof data != 'undefined' && typeof data.validated != 'undefined' && data.validated == FALSE &&
            <ValidateButton basePath={basePath} record={data} resource={resource} />
        }
        {typeof data != 'undefined' && typeof data.locked != 'undefined' && data.locked === Status.UNLOCKED &&
            <CustomerLockButton basePath={basePath} record={data} resource={resource} />
        }
        {typeof data != 'undefined' && typeof data.locked != 'undefined' && data.locked === Status.LOCKED &&
            <CustomerUnlockButton basePath={basePath} record={data} resource={resource} />
        }

        <DeleteButton resource="driver" basePath={basePath} record={data} content="Êtes-vous sûr de vouloir supprimer ce chauffeur? Toutes les donnnées personnelles de ce chauffeur seront supprimées" />
        {/* <EditButton basePath={basePath} record={data} /> */}
        <ListButton basePath={basePath} />
        {/* <BackButton/> */}

    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.driver.show')}: {record ? `${record.email}` : ''}</span>;
};



// const UserTypeField = ({ record = {}, label}) => {
//     return <div>
//         <Typography variant="body2">
//             {record.user_type == USER_TYPE_COMPANY_AND_DRIVER? 'Autre' : 'Auto-entrepreneur'}
//         </Typography>
//     </div>;
// }

// UserTypeField.defaultProps = {
//     addLabel: true,
// };


// const DocStatusReasonField = ({ record = {}, label}) => {
//     const classes = useStyles();
//     const statusData = record.status_data;
//     if(typeof(statusData) !== 'undefined' && statusData !== null && statusData !== ''){
//         const statusObj = JSON.parse(statusData); 
//         // return <span>{statusObj.reason}</span>;
//         return <Typography variant="body2" className={classes.colorPrimary}>{statusObj.reason}</Typography>
//     }
//     //     return <Typography variant="body2">
//     //     {statusData.reason}
//     // </Typography>
//     return <span></span>;
// }

// DocStatusReasonField.defaultProps = {
//     addLabel: true,
// };

// const ClientDataField = ({ record = {}, source, label}) => {
//     const client = record.client;
//     if(typeof(client) !== 'undefined' && client !== null && client !== ''){
//         const clientObj = JSON.parse(client); 
//         return <Typography variant="body2">{clientObj[source]}</Typography>
//     }

//     return <span></span>;
// }

// ClientDataField.defaultProps = {
//     addLabel: true,
// };


// const ValidityDateField = ({ record = {}, label}) => {
//     const classes = useStyles();
//     return record.date_id !== "" ? <div><br/>
//             <Typography variant="body2" className={classes.noColorLabel}>
//             Date de validité: {record.date !== "" ? <DateField className={classes.colorValue} source="date" record={record} label=""  locales="fr-FR"/> : '--'}
//             <DocDateEditButton record={record}/>
//         </Typography><br/>
//     </div> : '';
// }

// const PhotoField = ({ record = {}, source, label}) => {
//     // const classes = useStyles();
//     return <ImageViewer files={[]} fileUrl={record[source]} title={label} width='60' height='60' round='50%'/>

// }

const DocContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("File record " + JSON.stringify(record));
    var files = record.files;
    files.forEach(function (file, index) {
        if (!file.file_url.startsWith("http://") && !file.file_url.startsWith("https://")) {
            file.file_url = BACKEND_FILE_URL + file.file_url;
            files[index] = file;
        }
    }
        , files);

    if (files.length === 0)
        return <div>
            <ValidityDateField record={record} />
            <Typography variant="body2" className={classes.colorLabel}>Aucun fichier</Typography>
        </div>;

    return (
        <div style={styles.flexColumn}>
            <Typography variant="body2" className={classes.noColorLabel}>Fichier(s):</Typography>
            <br />
            <div style={styles.images}>
                {files.map(file => {
                    return <ImageViewer files={files} fileUrl={file.file_url} title="" width='300' height='300' className={classes.noColorLabel} />
                })}
            </div>
            <ValidityDateField record={record} />
            <div className={classes.noColorLabel}><span>Actions: </span>
                {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
                <DocRejectButton record={record} />
                {record.date !== '' &&
                    <DocApproveButton record={record} />}
                {/* <DocApproveButton record={record} /> */}
            </div>

        </div >
    )
};

const CardNumberField = ({ record, label }) => {
    // const classes = useStyles();
    // const data = JSON.parse(record.data);
    console.log('Card ' + JSON.stringify(record));
    console.log('Car data ' + JSON.stringify(record.data));
    return <div><span>{'xxxx xxxx xxxx ' + record.data.last4}</span></div>
    // return <div></div>
}

const ExpiryField = ({ record, label }) => {
    // const classes = useStyles();
    // const data = JSON.parse(record.data);
    //    console.log('Card ' + JSON.stringify(record));
    //  console.log('Car data ' + JSON.stringify(record.data));
    if (typeof (record) !== 'undefined' && typeof (record.data) !== 'undefined')
        return <div><span>{record.data.expiry_month + '/' + record.data.expiry_year}</span></div>
    return <div></div>
}

const VehicleDateField = ({ record = {}, label }) => {
    const classes = useStyles();
    return record.circulation_date !== "" ? <div><br />
        <Typography variant="body2" className={classes.noColorLabel}>
            Date de la 1e mise en circulation: {record.date !== "" ? <DateField className={classes.colorValue} source="circulation_date" record={record} label="" locales="fr-FR" /> : '--'}
            <VehicleDateEditButton record={record} />
        </Typography><br />
    </div> : '';
}

const VehicleContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("Vehicle record " + JSON.stringify(record));
    var transportInsuranceCertUrl = record.transport_insurance_cert_url;
    // var hasTransportInsurance = transportInsuranceCertUrl != null && transportInsuranceCertUrl != "";
    if (transportInsuranceCertUrl !== 'undefined' && transportInsuranceCertUrl !== '' && !transportInsuranceCertUrl.startsWith("http://") && !transportInsuranceCertUrl.startsWith("https://"))
        transportInsuranceCertUrl = BACKEND_FILE_URL + transportInsuranceCertUrl;
    var greenCardCertUrl = record.green_card_url;
    if (greenCardCertUrl !== 'undefined' && greenCardCertUrl !== '' && !greenCardCertUrl.startsWith("http://") && !greenCardCertUrl.startsWith("https://"))
        greenCardCertUrl = BACKEND_FILE_URL + greenCardCertUrl;
    var grayCardUrl = record.gray_card_url;
    if (grayCardUrl !== 'undefined' && grayCardUrl !== '' && !grayCardUrl.startsWith("http://") && !grayCardUrl.startsWith("https://"))
        grayCardUrl = BACKEND_FILE_URL + grayCardUrl;

    var frontImageUrl = record.front_image_url;
    if (frontImageUrl !== 'undefined' && frontImageUrl !== '' && !frontImageUrl.startsWith("http://") && !frontImageUrl.startsWith("https://"))
        frontImageUrl = BACKEND_FILE_URL + frontImageUrl;

    var rearImageUrl = record.rear_image_url;
    if (rearImageUrl !== 'undefined' && rearImageUrl !== '' && !rearImageUrl.startsWith("http://") && !rearImageUrl.startsWith("https://"))
        rearImageUrl = BACKEND_FILE_URL + rearImageUrl;

    var luggageBoxUrl = record.luggage_box_url;
    if (luggageBoxUrl !== 'undefined' && luggageBoxUrl !== '' && !luggageBoxUrl.startsWith("http://") && !luggageBoxUrl.startsWith("https://"))
        luggageBoxUrl = BACKEND_FILE_URL + luggageBoxUrl;

    var wholeImageUrl = record.whole_image_url;
    if (wholeImageUrl !== 'undefined' && wholeImageUrl !== '' && !wholeImageUrl.startsWith("http://") && !wholeImageUrl.startsWith("https://"))
        wholeImageUrl = BACKEND_FILE_URL + wholeImageUrl;

    var insideImageUrl = record.inside_image_url;
    if (insideImageUrl !== 'undefined' && insideImageUrl !== '' && !insideImageUrl.startsWith("http://") && !insideImageUrl.startsWith("https://"))
        insideImageUrl = BACKEND_FILE_URL + insideImageUrl;

    var files = [];
    files[0] = { file_url: greenCardCertUrl, name: 'Carte verte asurance' };
    files[1] = { file_url: transportInsuranceCertUrl, name: "Attestation d'assurance avec plaque du véhicule" };
    files[2] = { file_url: grayCardUrl, name: 'Carte grise ou contrat de location' };
    files[3] = { file_url: frontImageUrl, name: 'Photo du véhicule face avant plaque visible' };
    files[4] = { file_url: rearImageUrl, name: 'Photo du véhicule face arrière plaque visible' };
    files[5] = { file_url: wholeImageUrl, name: 'Photo du véhicule en largeur mode paysage' };
    files[6] = { file_url: insideImageUrl, name: 'Photo intérieure arrière du véhicule' };
    files[7] = { file_url: luggageBoxUrl, name: 'Photo du coffre' };



    return (

        <div style={styles.flexColumn}>
            <span>Couleur : {record['color']}</span><br />
            <span>Nombre de siège passager max. : {record['seats']}</span><br />
            <span>Nombre de bagage max. : {record['luggages']}</span><br />
            <span>Litrage du coffre : {record['luggage_volume']}</span><br />

            <span>Motorisation (Cheveaux fiscaux) : {record['horse_power']}</span><br />
            <div style={styles.images}>
                {files.map(file => {
                    return <ImageViewer files={files} fileUrl={file.file_url} title={file.name} width='300' height='300' />
                })}
            </div>
            {/* <VehicleDateField record={record}/> */}
            {/* <div className={classes.noColorLabel}><span>Actions: </span>
                {console.log("Vehicle is locked = " + record.is_locked)}
                {record.is_locked === Status.UNLOCKED && <VehicleLockButton record={record}/>}
                {record.is_locked === Status.LOCKED && <VehicleUnlockButton record={record}/>}
                {record.status == DataStatus.NEW && 
                <VehicleApproveButton record={record}/>}
                {record.status == DataStatus.NEW && 
                <VehicleRejectButton record={record}/>}
                </div> */}
        </div>
    );
};

const CoeffFields = ({ record }) => {
    // const classes = useStyles();
    // console.log("Vehicle record " + JSON.stringify(record));
    if (record === null || record === 'undefined') return <div />;
    return (

        <div style={styles.flexColumn}>
            <br />
            <p><span style={{ 'font-size': '14px' }}>&emsp;Prix par km (€/km): {record['price_km']}</span></p>
            <p><span style={{ 'font-size': '14px' }}>&emsp;Prix par minute (€/minute): {record['price_minute']}</span></p>
            <p><span style={{ 'font-size': '14px' }}>&emsp;Prix minimum (€): {record['price_min']}</span></p>
            <br />

        </div>
    );
};

const StripeConnectField = ({ id, record, resource }) => {
    const classes = useStyles();
    if (typeof (record.stripe_account) === 'undefined' || record.stripe_account === 'undefined' || record.stripe_account === null || record.stripe_account === "")
        return (<div style={styles.flexColumn}>
            <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize: 15 }}>Etat du compte Stripe</Typography>
            <br />
            <div>Aucun compte</div>

        </div>);
    var account = JSON.parse(record.stripe_account);
    console.log("Stripe account " + JSON.stringify(account));
    if (typeof (account) === 'undefined' || account === 'undefined' || account === null)
        return (<div style={styles.flexColumn}>
            <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize: 15 }}>Etat du compte Stripe</Typography>
            <br />
            <div>Aucun compte</div>

        </div>);
    var eventuallyDues = [];
    console.log("Stripe account type " + account.business_type);
    if (account.business_type === 'individual') {
        var individual = account.individual;
        if (typeof (individual) !== 'undefined' && individual !== 'undefined' && individual !== null) {
            eventuallyDues = individual.requirements.eventually_due;
            eventuallyDues = normalizeStripeConnectDues(eventuallyDues);
        }
    }
    else {
        // var company = account.company;
        // if(typeof(company) !== 'undefined' && company !== 'undefined' && company !== null){
        eventuallyDues = account.requirements.eventually_due;
        eventuallyDues = normalizeStripeConnectDues(eventuallyDues);
        // }

    }

    // requirements.forEach(function(requirement, index){
    //     if(!file.file_url.startsWith("http://") && !file.file_url.startsWith("https://")){
    //         file.file_url = BACKEND_FILE_URL + file.file_url;
    //         files[index] = file;
    //     }
    // }
    // , files);

    // if(eventuallyDues.length === 0)
    //     return account.charges_enabled && account.payouts_enabled ? <div>Transfert activé - Paiement activé
    //     </div> : account.charges_enabled && !account.payouts_enabled ? <div>Transfert activé - Paiement desactivé</div> : <div>Transfert desactivé - Paiement desactivé</div>;

    return (
        <div style={styles.flexColumn}>
            <div><Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize: 15 }}>Etat du compte Stripe</Typography>
                <StripeConnectStatusField record={record} /></div>

            {account.charges_enabled && account.payouts_enabled && <div>Transfert activé - Paiement activé</div>}
            {account.charges_enabled && !account.payouts_enabled && <div>Transfert activé - Paiement désactivé</div>}
            {!account.charges_enabled && !account.payouts_enabled && <div>Transfert désactivé - Paiement désactivé</div>}


            <br />
            {eventuallyDues.length > 0 && <Typography variant="body1">Informations à compléter:</Typography>}
            <br />
            <div>
                {eventuallyDues.map(eventuallyDue => {
                    return <div><span><Typography variant="body1"> - {getStripeConnectDueName(eventuallyDue)}</Typography></span></div>;
                })}
                <br />
            </div>
            {/* <ValidityDateField record={record}/>
            <div className={classes.noColorLabel}><span>Actions: </span> */}
            {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
            {/* <DocRejectButton record={record}/><DocApproveButton record={record}/></div> */}

        </div>
    )
};

const StripeConnectStatusField = ({ id, record, resource }) => {
    const classes = useStyles();
    if (typeof (record.stripe_account) === 'undefined' || record.stripe_account === 'undefined' || record.stripe_account === null || record.stripe_account === "")
        return <NotActivateIcon color='disabled' />

    var account = JSON.parse(record.stripe_account);
    console.log("Stripe account " + JSON.stringify(account));
    if (typeof (account) === 'undefined' || account === 'undefined' || account === null)
        return <NotActivateIcon color='disabled' />;
    var eventuallyDues = [];
    console.log("Stripe account type " + account.business_type);
    if (account.business_type === 'individual') {
        var individual = account.individual;
        if (typeof (individual) !== 'undefined' && individual !== 'undefined' && individual !== null) {
            eventuallyDues = individual.requirements.eventually_due;
        }
    }
    else {
        // var company = account.company;
        // if(typeof(company) !== 'undefined' && company !== 'undefined' && company !== null){
        eventuallyDues = account.requirements.eventually_due;
        // }

    }

    if (eventuallyDues.length > 0)
        return <NotActivateIcon color='disabled' />;
    return <ActivateIcon color='primary' />;
};

StripeConnectStatusField.defaultProps = {
    addLabel: true,
};


const ContentTextField = ({ source, record = {}, label }) => {
    if (typeof (record) !== 'undefined') {
        var content = record['content'];
        if (typeof (content) !== 'undefined' && content !== null)
            content = content.replace(/(<([^>]+)>)/ig, "");
        return <span class="MuiTypography-root MuiTypography-body2">{content}</span>
    }
    return <span></span>
}

ContentTextField.defaultProps = {
    addLabel: true,
};

const ManagerContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("Manager record " + JSON.stringify(record));
    var idCardRectoUrl = record.id_card_recto_url;
    // var hasTransportInsurance = transportInsuranceCertUrl != null && transportInsuranceCertUrl != "";
    if (idCardRectoUrl !== 'undefined' && idCardRectoUrl !== '' && !idCardRectoUrl.startsWith("http://") && !idCardRectoUrl.startsWith("https://"))
        idCardRectoUrl = BACKEND_FILE_URL + idCardRectoUrl;
    var idCardVersoUrl = record.id_card_verso_url;
    if (idCardVersoUrl !== 'undefined' && idCardVersoUrl !== '' && !idCardVersoUrl.startsWith("http://") && !idCardVersoUrl.startsWith("https://"))
        idCardVersoUrl = BACKEND_FILE_URL + idCardVersoUrl;
    var addressProofRectoUrl = record.address_proof_recto_url;
    if (addressProofRectoUrl !== 'undefined' && addressProofRectoUrl !== '' && !addressProofRectoUrl.startsWith("http://") && !addressProofRectoUrl.startsWith("https://"))
        addressProofRectoUrl = BACKEND_FILE_URL + addressProofRectoUrl;

    var files = [];
    files[0] = { file_url: idCardRectoUrl, name: "Pièce d'identité recto" };
    files[1] = { file_url: idCardVersoUrl, name: "Pièce d'identité verso" };
    files[2] = { file_url: addressProofRectoUrl, name: 'Justificatif de domicile' };

    return (

        <div style={styles.flexColumn}>
            <div style={styles.images}>
                {files.map(file => {
                    return <ImageViewer files={files} fileUrl={file.file_url} title={file.name} width='300' height='300' />
                })}
            </div>

        </div>
    );
};


const IbanActionWidget = ({ record = {}, }) => {
    const classes = useStyles();
    return <div className={classes.flex}>
        {record.iban_status == DataStatus.NEW &&
            <DriverFieldRejectButton record={record} fieldName="iban" fieldLabel="IBAN" />}
        {record.iban_status != DataStatus.APPROVED &&
            <DriverFieldApproveButton record={record} fieldName="iban" fieldLabel="IBAN" />}
    </div>;

}



IbanActionWidget.defaultProps = {
    addLabel: false,
};

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Email" source="email" alwaysOn resettable />
        <TextInput label="Mobile" source="mobile" alwaysOn resettable />
        {/* <TextInput label="Nom" source="last_name" alwaysOn resettable/>
        <TextInput label="Prénom" source="first_name" alwaysOn resettable/>
        <SelectInput label="Type" source="user_type" alwaysOn choices={driverTypes} 
            optionText={<UserTypeFilterField />} allowEmpty emptyText="Tous les types" emptyValue='0' 
            initialValue='0'
            />
        
        <TextInput label="Ville" source="city" resettable/>
        <TextInput label="Code postal" source="postal_code" resettable/>
        <SelectInput label="Civilité" source="gender" choices={genders} 
            allowEmpty emptyText="Tous les types" emptyValue='0' initialValue='0'
            optionText={<GenderFilterField />}/>

        <SelectInput label="Activation" source="activated" choices={activateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Vérrouillage" source="locked" choices={lockeds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            />
        <SelectInput label="Validation" source="validated" choices={validateds} 
            allowEmpty emptyText="Tous les deux" emptyValue='-1' initialValue='-1'
            /> */}
    </Filter>
);

// filter={{company_id: record.id}} 
const DriverList = ({ record = {}, ...props }) => {
    console.log('Driver list record id ' + record.id);
    return <ReferenceManyField reference="driver" target="company_id" addLabel={false}>
        {/* <List filter={{company_id: record.id}}  filters={<ListFilter />}> */}

        <Datagrid rowClick="show" {...props}>
            {/* <AvatarField source="photo_url" label="" /> */}
            <TextField source="id" label="ID" />
            <TextField source="last_name" label="Nom" />
            <TextField source="first_name" label="Prénom" />
            <TextField source="mobile" label="Mobile" />
            <TextField source="email" label="Email" />
            <FormattedDateField source="birth_date" label="Date de naissance" locales="fr-FR" />
            <DriverStatusField source="online_status" label="Statut" />
            <FormattedDateField source="create_time" label="Date d'inscription" locales="fr-FR" />
            {/* <ValidateField source="activated" label='Activé'/>
                    <ValidateField source="validated" label='Validé'/>*/}
            <ValidateField source="locked" label='Verrouillé' />
        </Datagrid>

        {/* </List> */}
    </ReferenceManyField>
}
// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');
const orderRowClick = (id, basePath, record) => '#/order/' + record.id;

const ShowDriver = ({ staticContext, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();

    return (

        <Show component="div" title={<Title />} actions={<ShowActions />} {...props}>
            <TabbedShowLayout redirect="list">
                <Tab label="Profil">

                    {/* <AvatarField source="photo_url" label="" size='80' /> */}
                    <PhotoField source="photo_url" label="" />

                    {/* <TextField source="id" label="ID"/> */}
                    {/* <GenderField label={translate('civility')}/> */}
                    {/* <TextField source="company_name" label="Nom de société"/>
                <TextField source="company_address" label="Adresse du siègle social"/>
                <div/> */}
                    <TextField source="last_name" label="Nom" className={classes.inlineBlock} />
                    <TextField source="first_name" label="Prénom " className={classes.inlineBlock} />
                    <div />
                    <div />
                    <TextField source="full_mobile" label="Mobile" className={classes.inlineBlock} />
                    <TextField source="email" label="Email" className={classes.inlineBlock} />
                    {/* <br/> */}
                    {/* <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/> */}



                    {/* <Divider/> */}

                    {/* <Typography variant="body2">État</Typography> */}
                    <ValidateField source="activated" label='Activé' className={classes.inlineBlock} />
                    <TextField source="activation_code" label="Code d'activation" />
                    <ValidateField source="validated" label='Validé' className={classes.inlineBlock} />
                    <ValidateField source="locked" label='Verrouillé' className={classes.inlineBlock} />

                    <TextField source="mode" label="Mode Stripe" className={classes.inlineBlock} />
                    <ValidateField source="stripe_validated" label='Validé par Stripe' className={classes.inlineBlock} />

                    <FormattedDateField source="create_time" label="Date d'inscription" locales="fr-FR" />
                    <FormattedDateField source="update_time" format="format_date_time" label="Profil modifié" locales="fr-FR" />


                    {/* <ValidateField source="locked" label='Verrouillé' className={classes.inlineBlock}/> */}
                    <br />

                    {/* <Divider/> */}
                    {/* <Typography variant="body2"><b>Véhicules du chauffeur</b></Typography>
                <br/>
                <ReferenceManyField pagination={<Pagination />} reference="vehicle" target="driver_id" addLabel={false}>
                        <Datagrid expand={<VehicleContentPanel/>}>
                        <TextField source="plate_number" label="Immatriculation"/>
                        <TextField source="brand_name" label="Marque"/>
                        <TextField source="model_name" label="Modèle"/>
                    
                        <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Gamme de véhicule" source="vehicle_class_id" reference="vehicle_class" link="">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Type de carburant" source="fuel_type_id" reference="fuel_type" link="">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Type de vêtement siège" source="seat_cover_type_id" reference="seat_cover_type" >
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceArrayField label="Options" reference="option" source="services">
                                        <SingleFieldList>
                                            <ChipField source="name" />
                                        </SingleFieldList>
                                    </ReferenceArrayField>
                        <FormattedDateField source="circulation_date" label="Date de première mise en circulation"  locales="fr-FR"/>
                        <FormattedDateField source="control_date" label="Date du contrôle technique"  locales="fr-FR"/>
                        <ValidateField source="is_default" label="Par défaut"/>
                        
                        
                    </Datagrid>
                </ReferenceManyField>
                
                <Typography variant="body2"><b>Commandes du chauffeur</b></Typography>
                <br/>
                <ReferenceManyField pagination={<Pagination />} reference="order" target="driver_id" addLabel={false}>
                        <Datagrid rowClick="">
                        <TextField source="create_time" label="Date\heure"/>
                        <TextField source="order_number" label="Nº de commande"/>
                        <ClientDataField source="last_name" label="Nom du client"/>
                        <ClientDataField source="first_name" label="Prénom du client"/>
                        <TextField source="from_address" label="Adresse de départ"/>
                        <TextField source="to_address" label="Adresse d'arrivée"/>
                        <TextField source="total" label="Prix(€)"/>
                        <OrderStatusField source="status" label = "État"/>
                        
                        </Datagrid>
                </ReferenceManyField> */}


                </Tab>


                <Tab label="Infos de la société">
                    {/* <PhotoField source="logo_url" label=""/> */}
                    <br />
                    <Typography variant="body2"><b>Infos</b></Typography>
                    <br />
                    <AvatarField source="photo_url" label="" size='200' />
                    {/* <DriverCompanyTypeField label="Type d'entreprise"/> */}
                    {/* <ActivityTypeField label="Type d'activité"/> */}
                    {/* <TextField source="company_type" label="Type d'entreprise" /> */}
                    <DriverCompanyTypeField label="Type de société" />

                    <TextField source="company_name" label="Nom de l'entreprise" />
                    <DriverFieldEditButton fieldName="company_name" fieldLabel="Nom de l'entreprise" />
                    <TextField source="company_address" label="Adresse" className={classes.inlineBlock} />

                    <TextField source="postal_code" label="Code postal" className={classes.inlineBlock} />
                    <TextField source="city" label="Ville" className={classes.inlineBlock} />
                    <TextField source="country_name" label="Pays" className={classes.inlineBlock} />
                    <div />
                    {/* <TextField source="phone" label = "Téléphone" className={classes.inlineBlock}/> */}
                    <TextField source="tax_id" label="Nº identification fiscale" />
                    <TextField source="siren_number" label="Nº de SIRET" className={classes.inlineBlock} />
                    <DriverFieldEditButton fieldName="siren_number" fieldLabel="Nº de SIRET" />
                    <TextField source="vat_no" label="Nº de TVA intracommunautaire" />
                    <DriverFieldEditButton fieldName="vat_no" fieldLabel="Nº de TVA intracommunautaire" />

                    <TextField source="iban" label="IBAN" className={classes.inlineBlock} />
                    <IbanActionWidget />

                    <div />

                    {/* <Typography style={{ fontWeight: 600, textDecorationLine: 'none', fontSize:13 }}>Représentant légal</Typography> */}
                    <br />
                    {/* className={classes.colorPrimary} */}
                    <Typography variant="body2"><b>Représentant légal</b></Typography>
                    <ReferenceManyField reference="customer_manager" target="driver_id" filter={{ 'type': '1' }} addLabel={false}>
                        <Datagrid expand={<ManagerContentPanel />}>
                            <TextField source="last_name" label="Nom" />
                            <TextField source="first_name" label="Prénom" />
                            <FormattedDateField source="birth_date" label="Date de naissance" locales="fr-FR" />
                            <TextField source="address" label="Adresse" />
                            <TextField source="postal_code" label="Code postal" />
                            <TextField source="city" label="Ville" />
                            <TextField source="country_name" label="Pays" />
                        </Datagrid>


                    </ReferenceManyField>

                    <br />
                    <Typography variant="body2"><b>Directeur général</b></Typography>

                    {/* <DirectorList/> */}
                    <ReferenceManyField reference="customer_manager" target="driver_id" filter={{ 'type': '2' }} addLabel={false}>
                        <Datagrid expand={<ManagerContentPanel />}>
                            <TextField source="last_name" label="Nom" />
                            <TextField source="first_name" label="Prénom" />
                            <FormattedDateField source="birth_date" label="Date de naissance" locales="fr-FR" />
                            <TextField source="address" label="Adresse" />
                            <TextField source="postal_code" label="Code postal" />
                            <TextField source="city" label="Ville" />
                        </Datagrid>


                    </ReferenceManyField>
                    <ValidateField source="director_done" label='Directeur général complété ?' />
                    <br />
                    <Typography variant="body2"><b>Associés</b></Typography>

                    {/* <DirectorList/> */}
                    <ReferenceManyField reference="customer_manager" target="driver_id" filter={{ 'type': '8' }} addLabel={false}>
                        <Datagrid expand={<ManagerContentPanel />}>
                            <TextField source="last_name" label="Nom" />
                            <TextField source="first_name" label="Prénom" />
                            <FormattedDateField source="birth_date" label="Date de naissance" locales="fr-FR" />
                            <TextField source="address" label="Adresse" />
                            <TextField source="postal_code" label="Code postal" />
                            <TextField source="city" label="Ville" />
                        </Datagrid>
                    </ReferenceManyField>
                    <ValidateField source="owner_done" label='Associés complétés ?' />
                    <br />
                    <Typography variant="body2"><b>Cadres</b></Typography>

                    {/* <DirectorList/> */}
                    <ReferenceManyField reference="customer_manager" target="driver_id" filter={{ 'type': '4' }} addLabel={false}>
                        <Datagrid expand={<ManagerContentPanel />}>
                            <TextField source="last_name" label="Nom" />
                            <TextField source="first_name" label="Prénom" />
                            <FormattedDateField source="birth_date" label="Date de naissance" locales="fr-FR" />
                            <TextField source="address" label="Adresse" />
                            <TextField source="postal_code" label="Code postal" />
                            <TextField source="city" label="Ville" />
                        </Datagrid>
                    </ReferenceManyField>
                    <ValidateField source="executive_done" label='Cadres complétés ?' />
                    {/* <br />
                    <TextField source="manager.last_name" label="Nom" className={classes.inlineBlock} />
                    <TextField source="manager.first_name" label="Prénom" className={classes.inlineBlock} />
                    <div />
                    <FormattedDateField source="manager.birth_date" label="Date de naissance" locales="fr-FR" />
                    <TextField source="manager.address" label="Adresse" className={classes.inlineBlock} />
                    <TextField source="manager.postal_code" label="Code postal" className={classes.inlineBlock} />
                    <TextField source="manager.city" label="Pays" className={classes.inlineBlock} />
                    <div /> */}


                    {/* <TextField source="activation_code" label="Code d'activation"/> */}
                </Tab>


                <Tab label="Compte Stripe">
                    <StripeConnectField />
                </Tab>

                <Tab label="Configuration des prix">
                    {/* <BooleanField source="settings.3rd_payment" label="Tiers payant"/> */}
                    <CoeffFields />
                    <DriverSettingsButton />
                </Tab>

                <Tab label="Documents d'activité">
                    <ReferenceManyField reference="doc" target="user_id" addLabel={false}>
                        <Datagrid expand={<DocContentPanel />} expandHeader={<div />}>
                            {/* <DocNameField label="Liste de documents d'activité du chauffeur"/> */}
                            <DocNameField label="Nom du document" />
                            <FileCountField label="Fichiers" />
                            <DocStatusField source="status" label="État" />
                            <DocStatusReasonField label="Motif de refus" />
                            {/* <RejectDocButtonX label="Actions"/>
                        <ApproveDocButton label="Actions"/> */}
                            {/* <OpenFileButton/> */}

                        </Datagrid>
                    </ReferenceManyField>
                    {/* <FileField source="files" src='url' title='title' label="Fichiers" target="_blank"/> */}
                    {/* <FileViewer source="files" src='url' /> */}
                </Tab>

                <Tab label="Chauffeurs">
                    {/* <DriverList/> */}
                    <ReferenceManyField pagination={<Pagination />} reference="driver_sub" target="company_id" addLabel={false}>

                        <Datagrid rowClick="show" {...props}>
                            <AvatarField source="photo_url" label="" />
                            <TextField source="user_code" label="Identifiant parrain" />
                            <TextField source="last_name" label="Nom" />
                            <TextField source="first_name" label="Prénom" />
                            <TextField source="mobile" label="Mobile" />
                            <TextField source="email" label="Email" />
                            {/* <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/> */}
                            {/* <DriverStatusField source="online_status" label="Statut"/> */}
                            {/* <FormattedDateField source="create_time" label="Date d'inscription"  locales="fr-FR"/> */}
                            {/* <ValidateField source="activated" label='Activé'/>
                    <ValidateField source="validated" label='Validé'/>*/}
                            {/* <ValidateField source="user_sub_type" label='Abonné' /> */}
                            <ValidateField source="locked" label='Verrouillé' />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>

                <Tab label="Véhicules">
                    <ReferenceManyField reference="vehicle" target="company_id" addLabel={false}>
                        <Datagrid rowClick="show">
                            <TextField source="plate_number" label="Plaque" className={classes.colorValue} />
                            {/* <ReferenceField label="Marque" source="brand_id" reference="vehicle_brand">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Modèle" source="model_id" reference="vehicle_model">
                            <TextField source="name" />
                        </ReferenceField> */}

                            <TextField source="brand_name" label="Marque" />
                            <TextField source="model_name" label="Modèle" />
                            {/* <TextField source="vehicle_type_name" label="Type"/> */}
                            {/* <TextField source="color" label="Couleur"/> */}
                            {/* <TextField source="color" label="Couleur"/> */}
                            <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="">
                                <TextField source="name" />
                            </ReferenceField>
                            {/* <ReferenceField label="Gamme de véhicule" source="vehicle_class_id" reference="vehicle_class" link="">
                                <TextField source="name" />
                            </ReferenceField> */}
                            {/* <ReferenceField label="Type de carburant" source="fuel_type_id" reference="fuel_type" link="">
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField label="Type de vêtement siège" source="seat_cover_type_id" reference="seat_cover_type" link="">
                                <TextField source="name" />
                            </ReferenceField>

                            <ReferenceArrayField label="Options" reference="option" source="services">
                                <SingleFieldList>
                                    <ChipField source="name" />
                                </SingleFieldList>
                            </ReferenceArrayField> */}
                            <FormattedDateField source="circulation_date" label="Date de la 1e mise en circulation" locales="fr-FR" />
                            <FormattedDateField source="control_date" label="Date du contrôle technique" locales="fr-FR" />
                            <ReferenceField label="Chauffeur" source="driver_id" reference="driver_all" link="" perPage={10000000}>
                                <TextField source="email" />
                            </ReferenceField>
                            {/* <ValidateField source="is_default" label="Par défaut"/> */}
                            <DataStatusField source="status" label="" />
                            <ValidateField source="is_locked" label="Vérrouillé" />




                        </Datagrid>
                    </ReferenceManyField>

                </Tab>
                {/* <Tab label="Cartes de paiement">
                    <ReferenceManyField pagination={<Pagination />} reference="card" target="user_id" addLabel={false}>
                        <Datagrid>
                            <CardNumberField label='Numéro de la carte' />
                            <ExpiryField label="Expiration" />
                            <TextField source="data.card_type" label="Type" />
                            <ValidateField source="is_default" label="Par défaut" />
                        </Datagrid>
                    </ReferenceManyField>

                </Tab> */}
                {/* <Tab label="Abonnements">
                    <br />
                    <Typography variant="body2"><b>Coefficients appliqués</b></Typography>

                    <br />
                    <CoeffFields />
                    <Typography variant="body2"><b>Abonnements</b></Typography>

                    <ReferenceManyField pagination={<Pagination />} reference="subscription" target="user_id" addLabel={false}>
                        <Datagrid>
                            <FormattedDateField label='De' source="from_date" />
                            <FormattedDateField label="À" source="to_date" />
                            <TextField source="amount" label="Montant" />
                            <ValidateField source="status" label="Payé ?" />
                        </Datagrid>
                    </ReferenceManyField>
                    <Typography variant="body2"><b>Chauffeurs partenaires</b></Typography>

                    <ReferenceManyField pagination={<Pagination />} reference="partner_driver" target="driver_id" addLabel={false}>
                        <Datagrid>
                            <TextField source="user_code" label="Identifiant parrain" />
                            <TextField source="last_name" label="Nom" />
                            <TextField source="first_name" label="Prénom" />
                            <TextField source="mobile" label="Mobile" />
                            <TextField source="email" label="Email" />

                            <ValidateField source="locked" label='Verrouillé' />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab> */}

                <Tab label="Commandes">
                    <ReferenceManyField pagination={<Pagination />} reference="order" target="company_id" addLabel={false}>
                        <Datagrid rowClick="show">
                            <TextField source="create_time" label="Date\heure" />
                            <TextField source="order_number" label="Nº de commande" />
                            <OrderTypeField source="order_type" label="Type de commande" />
                            {/* <ClientDataField source="last_name" label="Nom du client" />
                            <ClientDataField source="first_name" label="Prénom du client" /> */}
                            <TextField source="from_address" label="Adresse de départ" />
                            <TextField source="to_address" label="Adresse d'arrivée" />
                            <ReferenceField label="Client" source="client_id" reference="driver" link="" perPage={1000000}>
                                <TextField source="email" />
                            </ReferenceField>
                            <ReferenceField label="Chauffeur" source="driver_id" reference="driver" link="" perPage={1000000} >
                                <TextField source="email" />
                            </ReferenceField>
                            <TextField source="total" label="Prix(€)" />
                            <OrderStatusField source="status" label="État" />
                            {/* <TextField source="rating_on_driver" label="Note attribué par le client"/> */}
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="Parrainages">
                    <Typography variant="body2">Filleuls</Typography>
                    <ReferenceManyField style={{ 'width': '50%' }} pagination={<Pagination style={{ 'width': '80%' }} />} reference="driver_parrain" target="driver_id" filter={{ 'type': "child" }} addLabel={false}>
                        <Datagrid rowClick="">
                            <AvatarField source="photo_url" label="" size='200' />
                            <TextField source="email" label="Email" />
                            <TextField source="first_name" label="Prénom" />
                            <TextField source="last_name" label="Nom" />
                            <TextField source="order_count" label="Nombre de courses complétées" />
                            <TextField source="amount" label="Bonus à réserver (€)" />
                            <TextField source="remain_days" label="Jours restants" />
                        </Datagrid>
                    </ReferenceManyField>

                    <Typography variant="body2">Parrains</Typography>
                    <ReferenceManyField style={{ 'width': '40%' }} pagination={<Pagination />} reference="driver_parrain" target="driver_id" filter={{ 'type': "parent" }} addLabel={false}>
                        <Datagrid rowClick="">
                            <AvatarField source="photo_url" label="" size='200' />
                            <TextField source="email" label="Email" />
                            <TextField source="first_name" label="Prénom" />
                            <TextField source="last_name" label="Nom" />

                        </Datagrid>
                    </ReferenceManyField>

                    <Typography variant="body2">Clients</Typography>
                    <ReferenceManyField style={{ 'width': '40%' }} pagination={<Pagination />} reference="driver_parrain" target="driver_id" filter={{ 'type': "client" }} addLabel={false}>
                        <Datagrid rowClick="">
                            <AvatarField source="photo_url" label="" size='200' />
                            <TextField source="email" label="Email" />
                            <TextField source="first_name" label="Prénom" />
                            <TextField source="last_name" label="Nom" />

                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
                <Tab label="Statistiques">
                    {/* <Typography variant="body2">En construction</Typography> */}
                    <TextField source="statistics.cancel_rate" label="Taux d'annulation (%)" />
                    <TextField source="statistics.accept_rate" label="Taux d'acceptation (%)" />
                    <TextField source="statistics.completed" label="Courses complétées" />
                    <TextField source="statistics.parrain_client" label="Nombre de clients parrainés" />
                </Tab>
            </TabbedShowLayout>


        </Show >
    );
};


export default ShowDriver;