

import React, { Link } from 'react';
import {
    Labeled, Pagination, DateField, ReferenceField, TextField, Show, useTranslate, ImageField, TabbedShowLayout, Tab, UrlField,
    ReferenceManyField, Datagrid
} from 'react-admin';
import { EditButton, ListButton } from 'react-admin';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { BackButton, DeleteButton, CustomerLockButton, CustomerUnlockButton } from '../../control/button/index';
import ImageViewer from '../../control/viewer/image-viewer';
import {
    OrderStatusField, FormattedDateField, AvatarField, FullNameField, FullNameDriverField, FullNameClientField,
    GenderField, GenderFilterField, LockField, ValidateField, DocNameField, FileCountField, DriverDataField, OrderTypeField
} from '../../control/field/index'
import { Status, FALSE, TRUE, ANIMAL_TYPE_FREE, ANIMAL_TYPE_CAGE, USER_TYPE_CLIENT_PRO } from '../../util/constant'
// import {
//     Icon_Visa,
//     Icon_MasterCard
//   } from 'material-ui-credit-card-icons';

import { BACKEND_FILE_URL } from '../../util/variant'

import { makeStyles } from '@material-ui/core/styles';
import { bool } from 'prop-types';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: { paddingLeft: 50, color: '#c30040', },

    colorPrimary: { color: '#c30040', },

    noColorLabel: {
        paddingLeft: 50,
    },

    colorValue: { color: '#c30040', paddingLeft: 5 },

    supplementText: { color: '#c30040', },

    noHeader: {
        display: 'none',
        // height:'1px'
    },

    image: { width: '200px', height: '200px', }

});

const styles = {
    flex: { display: 'flex' },
    images: { display: 'flex', paddingLeft: '1em', marginLeft: "3em" },
    flexColumn: { display: 'flex', flexDirection: 'column', },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map: { flex: 1, marginTop: '1em', marginBottom: '1em' },
    button: { flex: 1, marginTop: '1em' },
};


const cardActionStyle = {
    // zIndex: 2,
    // display: 'inline-block',
    // float: 'right',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};




const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>
        {/* <EditButton basePath='/my-profile' record={data} /> */}
        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
        <ListButton basePath={basePath} />
        {/* <BackButton/> */}
        {/* <EditButton basePath={basePath} record={data} /> */}

        {typeof data != 'undefined' && typeof data.locked != 'undefined' && data.locked === Status.LOCKED &&
            <CustomerUnlockButton basePath={basePath} record={data} resource={resource} />
        }
        {typeof data != 'undefined' && typeof data.locked != 'undefined' && data.locked === Status.UNLOCKED &&
            <CustomerLockButton basePath={basePath} record={data} resource={resource} />
        }
        {/* <EditButton basePath={basePath} record={data} /> */}
        <DeleteButton resource="client" basePath={basePath} record={data} content="Êtes-vous sûr de vouloir supprimer ce client? Toutes les donnnées personnelles de ce client seront supprimées" />
    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.client.show')}: {record ? `${record.email}` : ''}</span>;
};

const CardNumberField = ({ record, label }) => {
    // const classes = useStyles();
    // const data = JSON.parse(record.data);
    console.log('Card ' + JSON.stringify(record));
    console.log('Car data ' + JSON.stringify(record.data));
    return <div><span>{'xxxx xxxx xxxx ' + record.data.last4}</span></div>
    // return <div></div>
}

const ExpiryField = ({ record, label }) => {
    // const classes = useStyles();
    // const data = JSON.parse(record.data);
    //    console.log('Card ' + JSON.stringify(record));
    //  console.log('Car data ' + JSON.stringify(record.data));
    if (typeof (record) !== 'undefined' && typeof (record.data) !== 'undefined') {
        var expMonth = record.data.exp_month;
        if (expMonth === 'undefined' || typeof (expMonth) === 'undefined') expMonth = record.data.expiry_month;
        var expYear = record.data.expiry_year;
        // if (expYear === 'undefined' || typeof (expYear) === 'undefined') expYear = record.data.expiry_year;
        return <div><span>{expMonth + '/' + expYear}</span></div>
    }

    return <div></div>
}

const ClientTypeField = ({ record }) => {
    return <span>{record.user_type == USER_TYPE_CLIENT_PRO ? 'Client professionnel' : 'Client particulier'}</span>;
};

ClientTypeField.defaultProps = {
    addLabel: true,
};



const DocContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("File record " + JSON.stringify(record));
    var files = record.files;
    files.forEach(function (file, index) {
        if (!file.file_url.startsWith("http://") && !file.file_url.startsWith("https://")) {
            file.file_url = BACKEND_FILE_URL + file.file_url;
            files[index] = file;
        }
    }
        , files);

    if (files.length === 0)
        return <div>
            {/* <ValidityDateField record={record}/> */}
            <Typography variant="body2" className={classes.colorLabel}>Aucun fichier</Typography>
        </div>;

    return (
        <div style={styles.flexColumn}>
            <Typography variant="body2" className={classes.noColorLabel}>Fichier:</Typography>
            <br />
            <div style={styles.images}>
                {files.map(file => {
                    return <ImageViewer files={files} fileUrl={file.file_url} title="" width='300' height='300' className={classes.noColorLabel} />
                })}
            </div>
            {/* <ValidityDateField record={record}/> */}
            {/* <div className={classes.noColorLabel}><span>Actions: </span> */}
            {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
            {/* <DocRejectButton record={record}/><DocApproveButton record={record}/></div> */}

        </div>

    )
};

// const CardTypeField = ({record, label}) => {
//     if(typeof(record) !== 'undefined' && typeof(record.data) !== 'undefined'){
//         if(record.data.card_type == "Visa")
//             return <Icon_Visa/>

//         if(record.data.card_type == "MasterCard")
//             return <Icon_MasterCard/>

//         return <span>{record.data.card_type}</span>
//     }
//     return <span>"Inconnu"</span>
// }

// const userId = localStorage.getItem('user_id');
// const role = localStorage.getItem('role');
const PhotoField = ({ record = {}, source, label }) => {
    // const classes = useStyles();
    return <ImageViewer files={[]} fileUrl={record[source]} title={label} width='60' height='60' round='50%' />

}

// const AnimalTypeField = ({record, label}) => {
//     // const classes = useStyles();
//     // const data = JSON.parse(record.data);
// //    console.log('Card ' + JSON.stringify(record));
//   //  console.log('Car data ' + JSON.stringify(record.data));
//     if(typeof(record) !== 'undefined' && typeof(record.data) !== 'undefined')
//         return <div><span>{record. + '/' + record.data.expiry_year}</span></div>    
//     return <div></div>
// }


const orderRowClick = (id, basePath, record) => '#/order/' + record.id;



const ShowClient = ({ staticContext, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <Show title={<Title />} {...props} actions={<ShowActions />}>
            <TabbedShowLayout redirect="list">
                <Tab label="Profil">
                    {/* <AvatarField source="photo_url" label="" size='160'/> */}
                    <PhotoField source="photo_url" label="" />
                    {/* <PhotoField source="photo_url" label=""/> */}
                    {/* <ImageField source="photo_url" label="" className="avatar"/> */}

                    {/* <TextField source="reg_code" label="Chauffeur parrain"/> */}
                    {/* <ClientTypeField label="Type de client"/> */}
                    {/* <TextField source="user_number" label="ID"/> */}
                    {/* <TextField source="id" label="ID"/> */}
                    {/* <GenderField label={translate('civility')}/> */}
                    <div />
                    <TextField source="company_name" label="Nom de société" className={classes.inlineBlock} />
                    <TextField source="vat_no" label="N° de TVA" className={classes.inlineBlock} />
                    <div/>
                    <TextField source="last_name" label="Nom" className={classes.inlineBlock} />
                    <TextField source="first_name" label="Prénom" className={classes.inlineBlock} />
                    <TextField source="reg_code" label="Code parrainage" />
                    {/* <br/> */}
                    <div />
                    <TextField source="mobile" label="Mobile" className={classes.inlineBlock} />
                    <TextField source="email" label="Email" className={classes.inlineBlock} />
                    {/* <br/> */}
                    <div />
                    {/* <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/> */}
                    <TextField source="birth_year" label="Année de naissance" />
                    <TextField source="address" label="Adresse" className={classes.inlineBlock} />
                    <TextField source="postal_code" label="Code postal" className={classes.inlineBlock} />
                    <TextField source="city" label="Ville" className={classes.inlineBlock} />
                    <div />
                    {/* <VerifyField source="activated" label="Mobile vérifié?"/> */}
                    {/* <TextField source="address" label="Adresse postale"/>

            <TextField source="country" label="Pays" className={classes.inlineBlock}/>
            <TextField source="nationality" label="Nationalité" className={classes.inlineBlock}/> */}
                    {/* <TextField source="wallet.balance" label="Portefeuille(€)" className={classes.inlineBlock}/> */}

                    <FormattedDateField source="create_time" label="Date d'inscription" locales="fr-FR" />
                    <FormattedDateField source="update_time" format="format_date_time" label="Profil modifié" locales="fr-FR" />

                    {/* <br/> */}
                    {/* <Divider/> */}
                    {/* <br/> */}
                    <TextField source="activation_code" label="Code d'activation" />
                    <ValidateField source="activated" label='Activé' />
                    <ValidateField source="locked" label='Verrouillé' />
                </Tab>

                {/* <Tab label="Carte d'identité">
            <ReferenceManyField reference="doc" target="user_id" addLabel={false}>
                    <Datagrid expand={<DocContentPanel/>} expandHeader={<div/>}>
                        
                        <DocNameField label=""/>
                        
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
             */}
                <Tab label="Cartes de paiement">
                    <ReferenceManyField pagination={<Pagination />} reference="card" target="user_id" addLabel={false}>
                        <Datagrid>
                            <CardNumberField label='Numéro de la carte' />
                            <ExpiryField label="Expiration" />
                            <TextField source="data.card_type" label="Type" />
                            <TextField source="data.country" label="Pays" />
                            <TextField source="mode" label="Mode" />
                            <ValidateField source="is_default" label="Par défaut" />
                        </Datagrid>
                    </ReferenceManyField>
                    <br />
                    <span>Les frais Stripe sont différents selon le pays émetteur de la carte: </span>
                    <br />
                    <span>- Cartes américaines (US): Frais = Total * 2.9% + 0.3€</span>
                    <span>- Cartes britanniques (GB): Frais = Total * 2.5% + 0.25€</span>
                    <span>- Cartes des pays d'Union Européenne: Frais = Total * 1.5% + 0.25€</span>
                    <span>- Cartes internationales: Frais = Total * 3.25% + 0.25€</span>
                    <br />
                </Tab>

                <Tab label="Commandes">
                    <ReferenceManyField pagination={<Pagination />} reference="order_client" target="client_id" addLabel={false}>
                        <Datagrid rowClick="show">
                            <TextField source="create_time" label="Date/heure" />
                            <TextField source="order_number" label="Nº de commande" />
                            <OrderTypeField source="order_type" label="Type de commande" />
                            <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="">
                                <TextField source="name" />
                            </ReferenceField>

                            <TextField source="from_address" label="Adresse de départ" />
                            <TextField source="to_address" label="Adresse d'arrivée" />
                            {/* <Typography style={{ fontWeight: 500, textDecorationLine: 'underline', fontSize:12 }}>Arrêts</Typography>
                <ReferenceManyField reference="order_stop" target="order_id" addLabel={false}>
                    
                    <Datagrid>
                        <TextField label="Adresse" source="address"/> */}
                            {/* <StopStatusTextField label="État" source="status"/> */}
                            {/* </Datagrid>
                </ReferenceManyField> */}

                            {/* <FullNameDriverField label="Chauffeur"/> */}
                            <ReferenceField label="Chauffeur" source="driver_id" reference="driver" link=""
                                perPage={9999999}>
                                <TextField source="full_name" />
                            </ReferenceField>
                            <TextField source="total" label="Prix(€)" />
                            <OrderStatusField source="status" label="État" />
                            {/* <TextField source="rating_on_client" label="Note attribué par le chauffeur"/> */}
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>);
}


export default ShowClient;
// import React, {Link} from 'react';
// import { Labeled, Pagination, DateField, ReferenceField, SimpleShowLayout,TextField, Show, useTranslate, ImageField, TabbedShowLayout, Tab, UrlField,
//     ReferenceManyField, Datagrid} from 'react-admin';
// import { EditButton, ListButton } from 'react-admin';
// import Divider from '@material-ui/core/Divider';
// import Typography from '@material-ui/core/Typography';
// import CardActions from '@material-ui/core/CardActions';
// import Button from '@material-ui/core/Button';
// import {WalletResetButton, DeleteButton, CustomerLockButton, CustomerUnlockButton} from '../../control/button/index';
// import ImageViewer from '../../control/viewer/image-viewer';
// import {OrderStatusField, FormattedDateField, AvatarField, FullNameDriverField,
//     GenderField, GenderFilterField, LockField, ValidateField, DocNameField, FileCountField, DriverDataField} from '../../control/field/index'
// import {Status, FALSE, TRUE, ANIMAL_TYPE_FREE, ANIMAL_TYPE_CAGE, USER_TYPE_CLIENT_PRO} from '../../util/constant'
// // import {
// //     Icon_Visa,
// //     Icon_MasterCard
// //   } from 'material-ui-credit-card-icons';
  
// import {BACKEND_FILE_URL} from '../../util/variant'

// import { makeStyles } from '@material-ui/core/styles';
// import { bool } from 'prop-types';

// const useStyles = makeStyles({
//     inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
//     colorLabel: { paddingLeft: 50, color: '#c30040',},

//     colorPrimary: {color:'#c30040',},
    
//     noColorLabel: {paddingLeft: 50,  
//     },

//     colorValue: {color: '#c30040',paddingLeft:5},

//     supplementText: {color: '#c30040',},

//     noHeader:{display:'none',
//     // height:'1px'
//     },

//     image:{width:'200px',height:'200px',}
    
// });

// const styles = {
//     flex: { display: 'flex'},
//     images:{display: 'flex', paddingLeft:'1em', marginLeft:"3em" },
//     flexColumn: { display: 'flex', flexDirection: 'column', },
//     leftCol: { flex: 1, marginRight: '1em' },
//     rightCol: { flex: 1, marginLeft: '1em' },
//     singleCol: { marginTop: '2em', marginBottom: '2em' },
//     map:{ flex: 1, marginTop: '1em', marginBottom: '1em' },
//     button:{ flex: 1, marginTop: '1em'},
// };


// const cardActionStyle = {
//     // zIndex: 2,
//     // display: 'inline-block',
//     // float: 'right',
//     zIndex: 2,
//     display: 'flex',
//     alignItems: 'flex-start',
//     justifyContent: 'flex-end',
//     flexWrap: 'wrap',
//     padding: 0,
// };




// const ShowActions = ({ basePath, data, resource }) => (
//     <CardActions style={cardActionStyle}>
//         {/* <EditButton basePath='/my-profile' record={data} /> */}
//         {/* Add your custom actions */}
//         {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
//         {/* <Link to="/change-password">Changer le mot de passe</Link> */}
//         <ListButton basePath={basePath}/>
//         {/* <BackButton/> */}
//         {/* <EditButton basePath={basePath} record={data} /> */}
        
//         {typeof data != 'undefined' &&  typeof data.locked != 'undefined' && data.locked === Status.LOCKED &&
//             <CustomerUnlockButton basePath={basePath} record={data} resource={resource}/>
//         }
//         {typeof data != 'undefined' &&  typeof data.locked != 'undefined' && data.locked === Status.UNLOCKED &&
//             <CustomerLockButton basePath={basePath} record={data} resource={resource}/>
//         }
//         {/* <EditButton basePath={basePath} record={data} /> */}
//         <DeleteButton resource="client" basePath={basePath} record={data} content="Êtes-vous sûr de vouloir supprimer ce client? Toutes les donnnées personnelles de ce client seront supprimées"/>
//     </CardActions>
// );

// const Title = ({ record }) => {
//     const translate = useTranslate();
//     return <span>{translate('resources.client_pro.show')}: {record ? `${record.email}` : ''}</span>;
// };

// // const WALLET_ACTION_END_CHARGE = 1;
// // const WALLET_ACTION_CANCEL_CHARGE = 2;
// // const WALLET_ACTION_CREATE_CHARGE = 3;
// // const WALLET_ACTION_TIPS = 4;
// // const WALLET_ACTION_RESET = 5;

// // const WalletActionField = ({ record = {action: 0}}) => {
// //     const classes = useStyles();
// //     let text = "";
// //     if(record.action == WALLET_ACTION_END_CHARGE) text = "Frais de la course";
// //     else
// //     if(record.action == WALLET_ACTION_CANCEL_CHARGE) text = "Frais d'annulation";
// //     else
// //     if(record.action == WALLET_ACTION_CREATE_CHARGE) text = "Frais de création de la course";
// //     else
// //     if(record.action == WALLET_ACTION_TIPS) text = "Pourboire";
// //     else
// //     if(record.action == WALLET_ACTION_RESET) text = "Remise à 0";
// //     return <div>
// //             <Typography variant="body2">
// //             {text}
// //         </Typography></div>;
    
// // }

// // WalletActionField.defaultProps = {
// //     addLabel: false,
// // };
// const CardNumberField = ({record, label}) => {
//     // const classes = useStyles();
//     // const data = JSON.parse(record.data);
//     console.log('Card ' + JSON.stringify(record));
//     console.log('Car data ' + JSON.stringify(record.data));
//     return <div><span>{'xxxx xxxx xxxx ' + record.data.last4}</span></div>    
//     // return <div></div>
// }

// const ExpiryField = ({record, label}) => {
//     // const classes = useStyles();
//     // const data = JSON.parse(record.data);
// //    console.log('Card ' + JSON.stringify(record));
//   //  console.log('Car data ' + JSON.stringify(record.data));
//     if(typeof(record) !== 'undefined' && typeof(record.data) !== 'undefined')
//         return <div><span>{record.data.expiry_month + '/' + record.data.expiry_year}</span></div>    
//     return <div></div>
// }

// const ClientTypeField = ({ record }) => {
//     return <span>{record.user_type == USER_TYPE_CLIENT_PRO ? 'Client professionnel' : 'Client particulier'}</span>;
// };

// ClientTypeField.defaultProps = {
//     addLabel: true,
// };

// // export const AnimalSizeField = ({ record = {}, label}) => {
// //     return record.animal.type === ANIMAL_TYPE_FREE ? <div>
// //     <Labeled  label="Taille (cm)">
// //         <Typography variant="body2">
// //             {record.animal.size}
// //         </Typography>
// //         </Labeled>
// //     </div> : <div/>;
// // }

// // AnimalSizeField.defaultProps = {
// //     addLabel: false,
// // };

// // export const AnimalCageSizeField = ({ record = {}, label}) => {
// //     var cageHeight = record.animal.cage_height;
// //     var cageWidth = record.animal.cage_width;
// //     var cageLength = record.animal.cage_length;
// //     var cageSize = record.animal.cage_size;
// //     var type = record.animal.type;
// //     return type === ANIMAL_TYPE_CAGE ? <div>
// //     <Labeled  label="Hauteur de la cage/panier(cm)">
// //         <Typography variant="body2">
// //             {cageHeight}
// //         </Typography>
// //         </Labeled>
// //         <Labeled  label="Largeur de la cage/panier(cm)">
// //         <Typography variant="body2">
// //             {cageWidth}
            
// //         </Typography>
// //         </Labeled>
// //         <Labeled  label="Longeur de la cage/panier(cm)">
// //         <Typography variant="body2">
// //             {cageLength}
// //         </Typography>
// //         </Labeled>
// //         <Labeled  label="Taille totale de la cage/panier(cm)">
// //         <Typography variant="body2">
// //             {cageSize}
// //         </Typography>
// //         </Labeled>
    
// //     </div> : <div/>;
// // }

// // AnimalCageSizeField.defaultProps = {
// //     addLabel: false,
// // };

// const DocContentPanel = ({ id, record, resource }) => {
//     const classes = useStyles();
//     console.log("File record " + JSON.stringify(record));
//     var files = record.files;
//     files.forEach(function(file, index){
//         if(!file.file_url.startsWith("http://") && !file.file_url.startsWith("https://")){
//             file.file_url = BACKEND_FILE_URL + file.file_url;
//             files[index] = file;
//         }
//     }
//     , files);

//     if(files.length === 0)
//         return <div>
//         {/* <ValidityDateField record={record}/> */}
//         <Typography variant="body2" className={classes.colorLabel}>Aucun fichier</Typography>
//         </div>;
    
//     return(
//         <div style={styles.flexColumn}>
//             <Typography variant="body2" className={classes.noColorLabel}>Fichier:</Typography>
//             <br/>
//             <div style={styles.images}>        
//                 {files.map(file => {
//                     return <ImageViewer files={files} fileUrl={file.file_url} title="" width='300' height='300' className={classes.noColorLabel}/>
//                 })}
//             </div>
//             {/* <ValidityDateField record={record}/> */}
//             {/* <div className={classes.noColorLabel}><span>Actions: </span> */}
//                 {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
//                 {/* <DocRejectButton record={record}/><DocApproveButton record={record}/></div> */}

//         </div>
        
//     )
// };

// // const CardTypeField = ({record, label}) => {
// //     if(typeof(record) !== 'undefined' && typeof(record.data) !== 'undefined'){
// //         if(record.data.card_type == "Visa")
// //             return <Icon_Visa/>
        
// //         if(record.data.card_type == "MasterCard")
// //             return <Icon_MasterCard/>

// //         return <span>{record.data.card_type}</span>
// //     }
// //     return <span>"Inconnu"</span>
// // }

// // const userId = localStorage.getItem('user_id');
// // const role = localStorage.getItem('role');
// const PhotoField = ({ record = {}, source, label}) => {
//     // const classes = useStyles();
//     return <ImageViewer files={[]} fileUrl={record[source]} title={label} width='60' height='60' round='50%'/>
        
// }

// const WalletBalanceField = ({ record = {wallet:{balance: 0}}}) => {
//     const classes = useStyles();
//     const value = record.wallet.balance;
//     return <div>
//             <Typography variant="body1" style={{ fontWeight: 600, fontSize:15 }}>
//             PORTEFEUILLE : {value}€
//         </Typography><WalletResetButton record={record} content="Voulez-vous remettre le solde du wallet du chauffeur à 0 ?"/></div>;
    
// }

// WalletBalanceField.defaultProps = {
//     addLabel: false,
// };

// // const AnimalTypeField = ({record, label}) => {
// //     // const classes = useStyles();
// //     // const data = JSON.parse(record.data);
// // //    console.log('Card ' + JSON.stringify(record));
// //   //  console.log('Car data ' + JSON.stringify(record.data));
// //     if(typeof(record) !== 'undefined' && typeof(record.data) !== 'undefined')
// //         return <div><span>{record. + '/' + record.data.expiry_year}</span></div>    
// //     return <div></div>
// // }


// const orderRowClick = (id, basePath, record) => '#/order/' + record.id;

// const ShowClient = ({staticContext, ...props}) => {
//     const translate = useTranslate();
//     const classes = useStyles();
//     return(
//     <Show title={<Title />} {...props} actions={<ShowActions />}>
//             <TabbedShowLayout redirect="list">
//             <Tab label="Profil">
//             <AvatarField source="logo_url" label="" />
//             <TextField source="reg_code" label="Chauffeur parrain"/>
            
//             {/* <TextField source="id" label="ID"/> */}
//             {/* <GenderField source="gender" label={translate('civility')}/> */}
//             <TextField source="company_name" label="Nom de l'entreprise"/>
//             <ReferenceField label="Activité de l'entreprise" source="service_type_id" reference="service_type" link="">
//                     <TextField source="name" />
//                 </ReferenceField>
//             <TextField source="address" label="Adresse"/>
//             <TextField source="postal_code" label="Code postal"/>
//             <TextField source="city" label="Ville"/>
                
                
//                 <div/>
//                 <TextField source="last_name" label="Nom du responsable des réservations" className={classes.inlineBlock}/>
//                 <TextField source="first_name" label="Prénom du responsable des réservations"className={classes.inlineBlock}/>
//                 <div/>
//                 <TextField source="mobile" label = "Mobile" className={classes.inlineBlock}/>
//                 <TextField source="email" label = "Email" className={classes.inlineBlock}/>
//                 <div/>
//                 <TextField source="manager_last_name" label="Nom du dirigeant" className={classes.inlineBlock}/>
//                 <TextField source="manager_first_name" label="Prénom du dirigeant" className={classes.inlineBlock}/>
//                 <TextField source="manager_phone" label="Téléphone du dirigeant" className={classes.inlineBlock}/>
                

//                 {/* <br/> */}

//                 {/* <FormattedDateField source="birth_date" label="Date de naissance"  locales="fr-FR"/> */}
//             {/* <VerifyField source="activated" label="Mobile vérifié?"/> */}
//             {/* <TextField source="address" label="Adresse postale"/>

//             <TextField source="country" label="Pays" className={classes.inlineBlock}/>
//             <TextField source="nationality" label="Nationalité" className={classes.inlineBlock}/> */}
//             {/* <TextField source="wallet.balance" label="Portefeuille(€)" className={classes.inlineBlock}/> */}

//             <FormattedDateField source="create_time" label="Date d'inscription"  locales="fr-FR"/>
                
//                 {/* <br/> */}
//                 {/* <Divider/> */}
//                 {/* <br/> */}
//                 {/* <TextField source="activation_code" label="Code d'activation"/> */}

//                 <ValidateField source="locked" label='Verrouillé'/>
//             </Tab>
//             {/* <Tab label="Wallet">
//             <br/>
//                 <WalletBalanceField/>
//                 <br/>
                
//                 <Typography style={{ fontWeight: 600, textDecorationLine: 'underline', fontSize:15 }}>Historique</Typography>
//                 <ReferenceManyField pagination={<Pagination />} reference="wallet_action" target="driver_id" addLabel={false}>
//                         <Datagrid rowClick="">
//                         <TextField source="create_time" label="Date"/>
//                         <TextField source="value" label="Valeur(€)"/>
//                         <WalletActionField source="action" label="Action"/>
//                         </Datagrid>
//                     </ReferenceManyField>
//             </Tab> */}
            
//             <Tab label="Documents">
//             <ReferenceManyField reference="doc" target="user_id" addLabel={false}>
//                     <Datagrid expand={<DocContentPanel/>} expandHeader={<div/>}>
//                         <DocNameField label=""/>
                        
//                     </Datagrid>
//                 </ReferenceManyField>
//             </Tab>
//             <Tab label="Sous-comptes">
//                 <ReferenceManyField pagination={<Pagination />} reference="client_sub" target="company_id" addLabel={false} >
//                 <Datagrid rowClick="show">
//                     <AvatarField source="photo_url" label="" />
//                     <TextField source="email" label="Email"/>
//                     <TextField source="last_name" label="Nom"/>
//                     <TextField source="first_name" label="Prénom"/>
//                     <ReferenceField label="Activité de l'entreprise" source="service_type_id" reference="service_type" link="">
//                             <TextField source="name" />
//                         </ReferenceField>
                    
//                     <TextField source="mobile" label="Mobile"/>
//                     <FormattedDateField source="create_time" label="Date d'inscription"  locales="fr-FR"/>
//                     <ValidateField source="locked" label='Verrouillé'/>
//                             </Datagrid>
//                         </ReferenceManyField>
                
//             </Tab>
            
//             <Tab label="Cartes de paiement">
//                 <ReferenceManyField pagination={<Pagination />} reference="card" target="user_id" addLabel={false}>
//                     <Datagrid>
//                         <CardNumberField label='Numéro de la carte'/>  
//                         <ExpiryField label="Expiration"/>
//                         <TextField source="data.card_type" label="Type"/>
//                         <ValidateField source="is_default" label="Par défaut"/>      
//                     </Datagrid>
//                 </ReferenceManyField>
                
//             </Tab>

//             <Tab label="Commandes">
//                 <ReferenceManyField pagination={<Pagination />} reference="order_client" target="client_id" addLabel={false}>
//                         <Datagrid rowClick="show">
//                         <TextField source="create_time" label="Date/heure"/>
//                         <TextField source="order_number" label="Nº de commande"/>
//                         {/* <DriverDataField source="last_name" label="Nom du chauffeur"/>
//                         <DriverDataField source="first_name" label="Prénom du chauffeur"/> */}
//                         <TextField source="from_address" label="Adresse de départ"/>
//                         <TextField source="to_address" label="Adresse d'arrivée"/>
//                         <FullNameDriverField label="Chauffeur"/>
//                         <TextField source="total" label="Prix(€)"/>
//                         <OrderStatusField source="status" label = "État"/>
//                         <TextField source="rating_on_client" label="Note attribué par le chauffeur"/>
//                         </Datagrid>
//                     </ReferenceManyField>
//             </Tab>
//             </TabbedShowLayout>
//     </Show>);
// }


// export default ShowClient;