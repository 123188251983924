
import React, { Link, Component } from 'react';
import { Pagination, SingleFieldList, ChipField, ReferenceArrayField, Labeled, ReferenceField, DateField, ReferenceManyField, Datagrid, TextField, Show, useTranslate, TabbedShowLayout, Tab, ImageField } from 'react-admin';
import { EditButton, ListButton } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { Form } from 'react-final-form'
import Divider from '@material-ui/core/Divider';
import { DocApproveButton, WalletResetButton, DocRejectButton, DeleteButton, AvatarButton, DriverSettingsButton, VehicleDocRejectButton, VehicleDocApproveButton } from '../../control/button/index';
import ActivateIcon from '@material-ui/icons/Check';
import NotActivateIcon from '@material-ui/icons/Clear';
import ImageViewer from '../../control/viewer/image-viewer';

import CardActions from '@material-ui/core/CardActions';

import {
    DataStatusField, DocStatusField, OrderStatusField, FormattedDateField, DriverStatusField, ActivityTypeField, AvatarField, FullNameClientField,
    ValidateField, ValidityDateField, DriverCompanyTypeField, FileCountField, ClientDataField, PhotoField, DocNameField, DocStatusReasonField, OrderTypeField
} from '../../control/field/index'
import {
    VehicleLockButton, VehicleUnlockButton, VehicleDateEditButton,
    VehicleApproveButton, VehicleRejectButton,
    DocDateEditButton, ValidateButton, CustomerLockButton, CustomerUnlockButton
} from '../../control/button/index'

import { Status, DataStatus, FALSE, TRUE, USER_TYPE_COMPANY_AND_DRIVER } from '../../util/constant'
import { BACKEND_FILE_URL } from '../../util/variant'

import { makeStyles } from '@material-ui/core/styles';
import { getStripeConnectDueName, normalizeStripeConnectDues } from '../../util/function';
import moment from 'moment';

const useStyles = makeStyles({
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
    colorLabel: { paddingLeft: 50, color: '#c30040', },

    colorPrimary: { color: '#c30040', },

    noColorLabel: {
        paddingLeft: 50,
    },

    colorValue: { color: '#c30040', paddingLeft: 5 },

    supplementText: { color: '#c30040', },

    noHeader: {
        display: 'none',
        // height:'1px'
    },

    image: { width: '200px', height: '200px', borderRadius: '50%' },


});

const styles = {
    flex: { display: 'flex' },
    images: { display: 'flex', paddingLeft: '1em', marginLeft: "3em" },
    flexColumn: { display: 'flex', flexDirection: 'column', },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
    map: { flex: 1, marginTop: '1em', marginBottom: '1em' },
    button: { flex: 1, marginTop: '1em' },
};

const cardActionStyle = {
    // zIndex: 2,
    // display: 'inline-block',
    // float: 'right',
    zIndex: 2,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: 0,
};





const ShowActions = ({ basePath, data, resource }) => (
    <CardActions style={cardActionStyle}>

        {/* Add your custom actions */}
        {/* <Button color="primary" onClick={changePassword}>CHANGER LE MOT DE PASSE</Button> */}
        {/* <Link to="/change-password">Changer le mot de passe</Link> */}
        {/* {typeof data != 'undefined' && typeof data.validated != 'undefined' && data.validated == FALSE &&
            <VehicleApproveButton basePath={basePath} record={data} resource={resource} />
        }
        {typeof data != 'undefined' && typeof data.locked != 'undefined' && data.locked === Status.UNLOCKED &&
            <CustomerLockButton basePath={basePath} record={data} resource={resource} />
        }
        {typeof data != 'undefined' && typeof data.locked != 'undefined' && data.locked === Status.LOCKED &&
            <CustomerUnlockButton basePath={basePath} record={data} resource={resource} />
        } */}
        {typeof data != 'undefined' && typeof data.status != 'undefined' && data.status !== DataStatus.APPROVED && <VehicleApproveButton basePath={basePath} record={data} resource={resource} />}
        {typeof data != 'undefined' && typeof data.status != 'undefined' && data.status !== DataStatus.REJECTED && <VehicleRejectButton basePath={basePath} record={data} resource={resource} />}
        <DeleteButton resource="driver" basePath={basePath} record={data} content="Êtes-vous sûr de vouloir supprimer ce chauffeur? Toutes les donnnées personnelles de ce chauffeur seront supprimées" />
        {/* <EditButton basePath={basePath} record={data} /> */}
        <ListButton basePath={basePath} />
        {/* <BackButton/> */}

    </CardActions>
);

const Title = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate('resources.vehicle.show')}: {record ? `${record.plate_number}` : ''}</span>;
};

const AvatarChangeButton = ({ record, basePath, resource }) => {
    return <AvatarButton basePath={basePath} record={record} resource={resource} />;
}

const DocContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("File record " + JSON.stringify(record));
    var files = record.files;
    files.forEach(function (file, index) {
        if (!file.file_url.startsWith("http://") && !file.file_url.startsWith("https://")) {
            file.file_url = BACKEND_FILE_URL + file.file_url;
            files[index] = file;
        }
    }
        , files);

    if (files.length === 0)
        return <div>
            {(record.doc_type == "17" || record.doc_type == "112") && <ValidityDateField record={record} />}
            <Typography variant="body2" className={classes.colorLabel}>Aucun fichier</Typography>
        </div>;

    return (
        <div style={styles.flexColumn}>
            <Typography variant="body2" className={classes.noColorLabel}>Fichier(s):</Typography>
            <br />
            <div style={styles.images}>
                {files.map(file => {
                    return <ImageViewer files={files} fileUrl={file.file_url} title="" width='300' height='300' className={classes.noColorLabel} />
                })}
            </div>
            {/* <ValidityDateField record={record} /> */}
            {(record.doc_type == "17" || record.doc_type == "112") && <ValidityDateField record={record} />}
            <div className={classes.noColorLabel}><span>Actions: </span>
                {/* {record.date !== '' && <ĐocDateEditButton record={record}/>} */}
                <VehicleDocRejectButton record={record} />
                {record.date !== '' &&
                    <VehicleDocApproveButton record={record} />}
            </div>

        </div>
    )
};

const VehicleDateField = ({ record = {}, label }) => {
    const classes = useStyles();
    return record.circulation_date !== "" ? <div><br />
        <Typography variant="body2" className={classes.noColorLabel}>
            Date de la 1e mise en circulation: {record.date !== "" ? <DateField className={classes.colorValue} source="circulation_date" record={record} label="" locales="fr-FR" /> : '--'}
            <VehicleDateEditButton record={record} />
        </Typography><br />
    </div> : '';
}
// className={classes.noColorLabel}
const BooleanField = ({ record = {}, source, label }) => {
    const classes = useStyles();
    const value = record[source] === "1" ? "Oui" : "Non";
    return <div>
        <Typography variant="body2">
            {value}€
        </Typography></div>;

}

BooleanField.defaultProps = {
    addLabel: true,
};


const VehicleContentPanel = ({ id, record, resource }) => {
    const classes = useStyles();
    console.log("Vehicle record " + JSON.stringify(record));
    var transportInsuranceCertUrl = record.transport_insurance_cert_url;
    // var hasTransportInsurance = transportInsuranceCertUrl != null && transportInsuranceCertUrl != "";
    if (transportInsuranceCertUrl !== '' && !transportInsuranceCertUrl.startsWith("http://") && !transportInsuranceCertUrl.startsWith("https://"))
        transportInsuranceCertUrl = BACKEND_FILE_URL + transportInsuranceCertUrl;
    var greenCardCertUrl = record.green_card_url;
    if (greenCardCertUrl !== '' && !greenCardCertUrl.startsWith("http://") && !greenCardCertUrl.startsWith("https://"))
        greenCardCertUrl = BACKEND_FILE_URL + greenCardCertUrl;
    var grayCardUrl = record.gray_card_url;
    if (grayCardUrl !== '' && !grayCardUrl.startsWith("http://") && !grayCardUrl.startsWith("https://"))
        grayCardUrl = BACKEND_FILE_URL + grayCardUrl;

    var frontImageUrl = record.front_image_url;
    if (frontImageUrl !== '' && !frontImageUrl.startsWith("http://") && !frontImageUrl.startsWith("https://"))
        frontImageUrl = BACKEND_FILE_URL + frontImageUrl;

    // var rearImageUrl = record.rear_image_url;
    // if(rearImageUrl !== '' && !rearImageUrl.startsWith("http://") && !rearImageUrl.startsWith("https://"))
    //     rearImageUrl = BACKEND_FILE_URL + rearImageUrl;

    // var luggageBoxUrl = record.luggage_box_url;
    // if(luggageBoxUrl !== '' && !luggageBoxUrl.startsWith("http://") && !luggageBoxUrl.startsWith("https://"))
    //     luggageBoxUrl = BACKEND_FILE_URL + luggageBoxUrl;

    // var wholeImageUrl = record.whole_image_url;
    // if(wholeImageUrl !== '' && !wholeImageUrl.startsWith("http://") && !wholeImageUrl.startsWith("https://"))
    //     wholeImageUrl = BACKEND_FILE_URL + wholeImageUrl;

    // var insideImageUrl = record.inside_image_url;
    // if(insideImageUrl !== '' && !insideImageUrl.startsWith("http://") && !insideImageUrl.startsWith("https://"))
    //     insideImageUrl = BACKEND_FILE_URL + insideImageUrl;

    var files = [];
    files[0] = { file_url: grayCardUrl, name: 'Carte grise (recto)' };
    files[1] = { file_url: transportInsuranceCertUrl, name: "Carte grise (verso)" };
    files[2] = { file_url: greenCardCertUrl, name: "Carte verte (certificat d'asurance du véhicule)" };
    files[3] = { file_url: frontImageUrl, name: 'Photo face avant du véhicule (plaque visible)' };
    // files[3] = {file_url: rearImageUrl, name: 'Photo face arrière du véhicule'};



    // files[5] = {file_url: wholeImageUrl, name: 'Photo du véhicule en largeur mode paysage'};
    // files[6] = {file_url: insideImageUrl, name: 'Photo intérieure arrière du véhicule'};
    // files[7] = {file_url: luggageBoxUrl, name: 'Photo du coffre'};



    return (

        <div style={styles.flexColumn}>
            {/* <span>Couleur : {record['color']}</span><br/>
                <span>Nombre de siège passager max. : {record['seats']}</span><br/> */}
            {/* <span>Nombre de bagage max. : {record['luggages']}</span><br/> */}
            {/* <span>Litrage du coffre : {record['luggage_volume']}</span><br/> */}

            {/* <span>Motorisation (Cheveaux fiscaux) : {record['horse_power']}</span><br/> */}
            {/* <ReferenceField label="Modèle" 
                        source="model_id" reference="vehicle_model"
                        perPage={9999999}>
                            <TextField source="name" />
                        </ReferenceField> */}
            <div style={styles.images}>
                {files.map(file => {
                    return <ImageViewer files={files} fileUrl={file.file_url} title={file.name} width='300' height='300' />
                })}
            </div>
            {/* <VehicleDateField record={record}/> */}
            {/* <div className={classes.noColorLabel}><span>Actions: </span>
                {console.log("Vehicle is locked = " + record.is_locked)}
                {record.is_locked === Status.UNLOCKED && <VehicleLockButton record={record}/>}
                {record.is_locked === Status.LOCKED && <VehicleUnlockButton record={record}/>}
                {record.status == DataStatus.NEW && 
                <VehicleApproveButton record={record}/>}
                {record.status == DataStatus.NEW && 
                <VehicleRejectButton record={record}/>}
                </div> */}
        </div>
    );
};

const ContentTextField = ({ source, record = {}, label }) => {
    if (typeof (record) !== 'undefined') {
        var content = record['content'];
        if (typeof (content) !== 'undefined' && content !== null)
            content = content.replace(/(<([^>]+)>)/ig, "");
        return <span class="MuiTypography-root MuiTypography-body2">{content}</span>
    }
    return <span></span>
}

ContentTextField.defaultProps = {
    addLabel: true,
};


const ShowVehicle = ({ staticContext, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();

    return (

        <Show component="div" title={<Title />} actions={<ShowActions />} {...props}>
            <TabbedShowLayout redirect="list">
                <Tab label="Info">
                    <TextField source="plate_number" label="Plaque" />
                    {/* <ReferenceField label="Marque" source="brand_id" reference="vehicle_brand"
                        perPage={9999999}>
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Modèle" 
                        source="model_id" reference="vehicle_model"
                        perPage={9999999}>
                            <TextField source="name" />
                        </ReferenceField> */}

                    <TextField source="brand_name" label="Marque" className={classes.inlineBlock} />
                    <TextField source="model_name" label="Modèle" className={classes.inlineBlock} />
                    <div />
                    {/* <TextField source="vehicle_type_name" label="Type"/> */}
                    {/* <TextField source="color" label="Couleur"/> */}
                    {/* <TextField source="color" label="Couleur"/> */}
                    <ReferenceField label="Type de véhicule" source="vehicle_type_id" reference="vehicle_type" link="">
                        <TextField source="name" />
                    </ReferenceField>
                    {/* <ReferenceField label="Gamme de véhicule" source="vehicle_class_id" reference="vehicle_class" link="">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Type de carburant" source="fuel_type_id" reference="fuel_type" link="">
                            <TextField source="name" />
                        </ReferenceField>
                        <ReferenceField label="Type de vêtement siège" source="seat_cover_type_id" reference="seat_cover_type" link="">
                            <TextField source="name" />
                        </ReferenceField>

                        <ReferenceArrayField label="Options" reference="option" source="services">
                                        <SingleFieldList>
                                            <ChipField source="name" />
                                        </SingleFieldList>
                                    </ReferenceArrayField> */}
                    <TextField source="color" label="Couleur" className={classes.inlineBlock} />
                    <ValidateField source="is_electric" label="Énergie: électrique & hybride ?" className={classes.inlineBlock} />
                    <div />
                    <TextField source="seats" label="Nombre de passagers max." className={classes.inlineBlock} />
                    <TextField source="luggages" label="Nombre de bagages max." className={classes.inlineBlock} />
                    <div />
                    <FormattedDateField source="circulation_date" label="Date de la 1e mise en circulation" locales="fr-FR" className={classes.inlineBlock} />
                    <FormattedDateField source="control_date" label="Date du prochain contrôle technique" locales="fr-FR" className={classes.inlineBlock} />
                    <div />
                    {/* <ValidateField source="is_default" label="Par défaut" /> */}
                    <DataStatusField source="status" label="État" />
                    {/* <ValidateField source="is_locked" label="Vérrouillé" /> */}

                </Tab>



                <Tab label="Documents">
                    <ReferenceManyField reference="vehicle_doc" target="vehicle_id" addLabel={false}>
                        <Datagrid expand={<DocContentPanel />} expandHeader={<div />}>
                            {/* <DocNameField label="Liste de documents d'activité du chauffeur"/> */}
                            <DocNameField label="Nom du document" />
                            <FileCountField label="Fichiers" />
                            <DocStatusField source="status" label="État" />
                            <DocStatusReasonField label="Motif de refus" />
                            {/* <RejectDocButtonX label="Actions"/>
                        <ApproveDocButton label="Actions"/> */}
                            {/* <OpenFileButton/> */}

                        </Datagrid>
                    </ReferenceManyField>
                    {/* <FileField source="files" src='url' title='title' label="Fichiers" target="_blank"/> */}
                    {/* <FileViewer source="files" src='url' /> */}
                </Tab>

            </TabbedShowLayout>


        </Show>
    );
};


export default ShowVehicle;

